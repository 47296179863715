import { DraftVM } from "./DraftVM";
import { useTranslation } from "../../../../i18n";
import { Box, Sheet, Typography } from "@mui/joy";
import { useRxVal } from "../../../../hooks/useRx";
import { DateTime } from "../../../../components/DateTime";
import Table from "@mui/joy/Table";

export function Samplings({ vm }: { vm: DraftVM }) {
  const t = useTranslation();
  const sampling = useRxVal(vm.samplings$);

  return (
    <Sheet sx={{ height: 300, overflow: "auto" }}>
      <Table stickyHeader stickyFooter>
        <thead>
          <tr>
            <th>{t("SKU")}</th>
            <th>{t("QTY")}</th>
            <th>{t("IL")}</th>
            <th>{t("Critical")}</th>
            <th>{t("Major")}</th>
            <th>{t("Minor")}</th>
          </tr>
        </thead>
        <tbody>
          {sampling?.map((sample: any) => (
            <tr key={sample.id}>
              <td>{sample.sku}</td>
              <td>{sample.quantity}</td>
              <td>{sample.inspectinLevel}</td>
              <td>
                <Table>
                  <tbody>
                    <tr>
                      <td>AQL {sample.criticalDefects.aql}</td>
                      <td>SS {sample.criticalDefects.sampleSize}</td>
                    </tr>
                    <tr>
                      <td>AP {sample.criticalDefects.acceptPoint}</td>
                      <td>RP {sample.criticalDefects.rejectPoint}</td>
                    </tr>
                  </tbody>
                </Table>
              </td>
              <td>
                <Table>
                  <tbody>
                    <tr>
                      <td>AQL {sample.majorDefects.aql}</td>
                      <td>SS {sample.majorDefects.sampleSize}</td>
                    </tr>
                    <tr>
                      <td>AP {sample.majorDefects.acceptPoint}</td>
                      <td>RP {sample.majorDefects.rejectPoint}</td>
                    </tr>
                  </tbody>
                </Table>
              </td>
              <td>
                <Table>
                  <tbody>
                    <tr>
                      <td>AQL {sample.minorDefects.aql}</td>
                      <td>SS {sample.minorDefects.sampleSize}</td>
                    </tr>
                    <tr>
                      <td>AP {sample.minorDefects.acceptPoint}</td>
                      <td>RP {sample.minorDefects.rejectPoint}</td>
                    </tr>
                  </tbody>
                </Table>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Sheet>
  );
}

export default function StepReview({ vm }: { vm: DraftVM }) {
  const t = useTranslation();
  const serviceType = useRxVal(vm.serviceType$);
  const supplier = useRxVal(vm.supplier$);
  const address = useRxVal(vm.addressStr$);
  const serviceDate = useRxVal(vm.serviceDate$);
  const notes = useRxVal(vm.notes$);

  return (
    <Box
      sx={{
        gap: 2,
        p: 2,
        display: "grid",
        gridTemplateColumns: { xs: "auto", sm: "auto 1fr" },
        "& > *:nth-child(odd)": { color: "text.secondary" },
      }}
    >
      <Typography level="title-sm">{t("Service Type")}</Typography>
      <Typography level="body-sm" textColor="text.primary">
        {serviceType}
      </Typography>
      <Typography level="title-sm">{t("Supplier")}</Typography>
      <Typography level="body-sm" textColor="text.primary">
        {supplier?.name}
      </Typography>
      <Typography level="title-sm">{t("Address")}</Typography>
      <Typography level="body-sm" textColor="text.primary">
        {address}
      </Typography>
      <Typography level="title-sm">{t("Sampling")}</Typography>
      <Typography level="body-sm" textColor="text.primary">
        <Samplings vm={vm} />
      </Typography>
      {serviceDate && (
        <>
          <Typography level="title-sm">{t("Dates")}</Typography>
          <Typography level="body-sm" textColor="text.primary">
            <DateTime value={serviceDate} />
          </Typography>
        </>
      )}
      <Typography level="title-sm">{t("Notes")}</Typography>
      <Typography variant="outlined" level="body-sm" textColor="text.primary">
        <pre>{notes}</pre>
      </Typography>
    </Box>
  );
}

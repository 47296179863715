import * as React from "react";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import SignInForm, { SignInVM } from "./SignInForm";
import { Stack } from "@mui/joy";
import { useTranslation } from "../../i18n";

export default function SignIn() {
  const vm = React.useMemo(() => new SignInVM(), []);
  const t = useTranslation();

  return (
    <>
      <Stack gap={4} sx={{ mb: 2 }}>
        <Stack gap={1}>
          <Typography component="h1" level="h3">
            {t("Sign in")}
          </Typography>
          <Typography level="body-sm">
            {t("New to company?")}{" "}
            <Link href="/auth/sign-up" level="title-sm">
              {t("Sign up!")}
            </Link>
          </Typography>
        </Stack>
        {/* <Button
                variant="soft"
                color="neutral"
                fullWidth
                startDecorator={<GoogleIcon />}
                onClick={handleGoogleAuth}
              >
                {t("Continue with Google")}
              </Button> */}
      </Stack>
      {/* <Divider
              sx={(theme) => ({
                [theme.getColorSchemeSelector("light")]: {
                  color: { xs: "#FFF", md: "text.tertiary" },
                },
              })}
            >
              or
            </Divider> */}
      <Stack gap={4} sx={{ mt: 2 }}>
        <SignInForm vm={vm} />
      </Stack>
    </>
  );
}

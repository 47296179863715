import { format } from "date-fns";
import { useLocale } from "../i18n";

export type DateTimeProps = {
  value: Date;
};

export function DateTime({ value }: DateTimeProps) {
  const locale = useLocale();

  return <>{format(value, "P", { locale })}</>;
}

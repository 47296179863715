import { DraftVM } from "./DraftVM";
import { useTranslation } from "../../../../i18n";
import { XDatePicker } from "../../../../x-components/XDatePicker";
import { XTextArea } from "../../../../x-components/XTextArea";

export default function StepOther({ vm }: { vm: DraftVM }) {
  const t = useTranslation();

  return (
    <>
      <XDatePicker label={t("Service Date")} val$={vm.serviceDate$} />
      <XTextArea
        placeholder="Additional Notes"
        minRows={5}
        label={t("Notes")}
        required
        val$={vm.notes$}
      />
    </>
  );
}

import { useTeam } from "../TeamLayout";
import { ConfigurationCard } from "./ConfigurationCard";
import SettingsRounded from "@mui/icons-material/SettingsRounded";
import { useNavigate } from "react-router-dom";
import { AspectRatio, Box, Stack, Typography } from "@mui/joy";

function TeamCard() {
  const navigate = useNavigate();
  const { team } = useTeam();

  return (
    <ConfigurationCard
      title="Team Apperance"
      link={
        window.location.protocol + "//" + window.location.host + "/" + team.slug
      }
      onClick={() => navigate("edit_team")}
      buttonText="Edit"
      buttonStartDecorator={<SettingsRounded />}
    >
      <Stack gap={1}>
        <Stack direction="row" justifyContent="space-between">
          <Stack gap={1}>
            <Typography level="title-sm">
              Name:{" "}
              <Typography
                noWrap
                textColor="text.tertiary"
                sx={{ fontSize: "sm" }}
              >
                {team.name}
              </Typography>
            </Typography>
            <Typography level="title-sm">
              Slug:{" "}
              <Typography
                noWrap
                textColor="text.tertiary"
                sx={{ fontSize: "sm" }}
              >
                {team.slug}
              </Typography>
            </Typography>
            <Typography level="title-sm">
              Kind:{" "}
              <Typography
                noWrap
                textColor="text.tertiary"
                sx={{ fontSize: "sm" }}
              >
                {team.kind}
              </Typography>
            </Typography>
          </Stack>
          <AspectRatio
            objectFit="contain"
            ratio="1"
            variant="outlined"
            sx={{ borderRadius: "10px", width: "64px", height: "64px" }}
          >
            <img src={team.picture} alt="" />
          </AspectRatio>
        </Stack>

        <Typography
          noWrap
          level="title-sm"
          sx={{
            fontSize: "sm",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          Description:{" "}
          <Typography
            noWrap
            textColor="text.tertiary"
            sx={{
              fontSize: "sm",
            }}
          >
            {team.description}
          </Typography>
        </Typography>
      </Stack>
    </ConfigurationCard>
  );
}

export default function General() {
  return (
    <Box
      sx={{
        width: "100%",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(min(100%, 300px), 1fr))",
        gap: 2,
        p: 2,
      }}
    >
      <TeamCard />
      {/* <EditTeam
        team$={
          new Team$(
            ctx.team.id,
            new BehaviorSubject(ctx.team.name),
            new BehaviorSubject(ctx.team.slug),
            new BehaviorSubject(ctx.team.kind as "BRAND" | "AGENCY"),
            new BehaviorSubject(ctx.team.picture),
            new BehaviorSubject(ctx.team.description ?? ""),
            new BehaviorSubject([] as Record<string, string>[])
          )
        }
      /> */}
    </Box>
  );
}

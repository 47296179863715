import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Checkbox from "@mui/joy/Checkbox";
import Link from "@mui/joy/Link";
import Stack from "@mui/joy/Stack";
import { XTextInput } from "../../x-components/XTextInput";
import EmailIcon from "@mui/icons-material/Email";
import PasswordIcon from "@mui/icons-material/Password";
import { useRxVal } from "../../hooks/useRx";
import { Alert } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../i18n";
import { computed } from "../../decorators/computed";
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  map,
  Subject,
} from "rxjs";
import { accessTokenManager } from "../../models/AccessTokenManager";

export class SignInVM {
  public email$: Subject<string> = new BehaviorSubject("");
  public password$: Subject<string> = new BehaviorSubject("");

  public status$ = new BehaviorSubject<{
    status: "ready" | "pending" | "error";
    error?: string;
  }>({ status: "ready" });

  @computed
  get email$error$() {
    return this.email$.pipe(
      map((email) => {
        if (email === null || email.length === 0) {
          return "Email is required.";
        }
        return null;
      })
    );
  }

  @computed
  get password$error$() {
    return this.password$.pipe(
      map((password) => {
        if (password === null || password.length === 0) {
          return "Password is required.";
        }
        return null;
      })
    );
  }

  @computed
  get json$() {
    return combineLatest({
      email: this.email$,
      password: this.password$,
    });
  }

  async signIn(persistent: boolean): Promise<boolean> {
    try {
      const { email, password } = await firstValueFrom(this.json$);
      this.status$.next({ status: "pending" });
      await accessTokenManager.signIn(email, password, persistent);
      this.status$.next({ status: "ready" });
      return true;
    } catch (e: any) {
      console.error(e.message);
      this.status$.next({ status: "error", error: e.message });
    }
    return false;
  }
}

export interface SignInFormProps {
  vm: SignInVM;
}

export default function SignInForm({ vm }: SignInFormProps) {
  const navagate = useNavigate();
  const [persistent, setPersistent] = React.useState<boolean>(true);
  const [showError, setShowError] = React.useState<boolean>(false);
  const status = useRxVal(vm.status$);
  const t = useTranslation();

  const onSubmit = React.useCallback(
    async (e: any) => {
      e.preventDefault();

      setShowError(true);
      if (await vm.signIn(persistent)) {
        navagate("/");
      }
    },
    [vm, persistent, navagate]
  );

  const onPersistentChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPersistent(e.target.checked);
    },
    []
  );

  return (
    <form>
      <XTextInput
        required
        label={t("Email")}
        size="sm"
        placeholder="email"
        type="email"
        startDecorator={<EmailIcon />}
        val$={vm.email$}
        error$={vm.email$error$}
        showError={showError}
      />
      <XTextInput
        required
        label={t("Password")}
        size="sm"
        placeholder="password"
        type="password"
        startDecorator={<PasswordIcon />}
        val$={vm.password$}
        error$={vm.password$error$}
        showError={showError}
        slotProps={{
          input: {
            autoComplete: "on",
          },
        }}
      />
      <Stack gap={4} sx={{ mt: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Checkbox
            size="sm"
            label={t("Remember me")}
            name="persistent"
            checked={persistent}
            onChange={onPersistentChange}
          />
          <Link level="title-sm" href="/auth/forgot-password">
            {t("Forgot your password?")}
          </Link>
        </Box>
        <Button onClick={onSubmit} type="submit" fullWidth>
          {t("Sign in")}
        </Button>
        <Alert
          sx={{
            opacity: status.status === "error" ? 1 : 0,
            transition: "opacity 0.3",
          }}
          color="danger"
        >
          {status.error ?? "Invalid email or password"}
        </Alert>
      </Stack>
    </form>
  );
}

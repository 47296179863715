import { useState } from "react";
import { useNotify } from "../hooks/useNotify";
import { IconButtonProps } from "@mui/joy/IconButton";
import { accessTokenManager } from "../models/AccessTokenManager";

export interface FileUploadButtonProps {
  entityType: string;
  entityId?: string;
  resource?: string;
  multiple?: boolean;
}

function FileUploadButton({
  entityType,
  entityId,
  resource,
  multiple,
}: FileUploadButtonProps & Omit<IconButtonProps, "children">) {
  const [progress, setProgress] = useState(0);
  const notify = useNotify();

  const handleFileChange = async (e: any) => {
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);

    try {
      const xhr = new XMLHttpRequest();

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = Math.round(
            (event.loaded / event.total) * 100
          );
          setProgress(percentComplete);
        }
      };

      let url = `https://api.qcsku.com/files/${entityType}`;
      if (typeof entityId !== "undefined") {
        url += `/${entityId}`;
      }
      if (typeof resource !== "undefined") {
        url += `/${resource}`;
      }
      xhr.open("POST", url, true);
      xhr.setRequestHeader(
        "authorization",
        `Bearer ${accessTokenManager.retrieve()}`
      );
      xhr.send(formData);

      xhr.onload = () => {
        if (xhr.status === 200) {
          notify({
            type: "success",
            msg: "The file(s) upload was successful.",
          });
        } else {
          notify({
            type: "danger",
            msg: "The file(s) upload has failed.",
          });
        }
      };
    } catch (error) {
      console.error("Upload error:", error);
      notify({
        type: "danger",
        msg: "The file(s) upload has failed.",
      });
    }
  };

  console.log("progress", progress);

  return (
    <input
      id="uploader"
      type="file"
      multiple={multiple}
      hidden
      onChange={handleFileChange}
    />
  );
}

export default FileUploadButton;

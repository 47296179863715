import Box from "@mui/joy/Box";
import LanguageToggle from "../../components/header/LanguageToggle";
import ProfileDropDown from "../../components/header/ProfileDropDown";
import ColorSchemeToggle from "../../components/header/ColorSchemeToggle";
import { useNavigate } from "react-router-dom";
import { IconButton, Stack } from "@mui/joy";
import HomeIcon from "@mui/icons-material/Home";

export default function Header() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        justifyContent: "space-between",
      }}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{ display: { sm: "flex" } }}
      >
        <IconButton variant="soft" size="md" onClick={() => navigate("/")}>
          <HomeIcon />
        </IconButton>
      </Stack>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1.5,
          alignItems: "center",
        }}
      >
        <LanguageToggle />
        <ColorSchemeToggle />
        <ProfileDropDown />
      </Box>
    </Box>
  );
}

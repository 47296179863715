import LocalShippingIcon from "@mui/icons-material/LocalShipping";

export function PSIIcon() {
  return <LocalShippingIcon />;
}

export const PSILabel = "Pre-Shipment Inspection";
export const PSILabelShort = "PSI";

export const PSIValue = "PSI";

export const PSIDescription =
  "Assess the quality and quantity of raw materials and components";

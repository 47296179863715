import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import { useTranslation } from "../../../i18n";
import { Link, useNavigate } from "react-router-dom";
import { useTeam } from "../TeamLayout";
import { AspectRatio, Button } from "@mui/joy";
import { useCallback } from "react";

export default function Inspections() {
  const t = useTranslation();
  const teamCtx = useTeam();
  const navigate = useNavigate();

  const handleAddInspection = useCallback(() => {
    navigate(`/${teamCtx.team.slug}/inspections/new`);
  }, [navigate, teamCtx]);

  return (
    <>
      <Box sx={{ display: "flex", m: 2 }}>
        <Typography sx={{ flex: 1 }} level="h2" component="h1">
          {t("Inspections")}
        </Typography>
        <Button variant="solid" onClick={handleAddInspection}>
          {t("Add Inspection")}
        </Button>
      </Box>
      <Box
        sx={{
          gap: 2,
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <>
          <Typography color="neutral" level="body-md">
            {t(
              "No Inspections yet! Start by adding one manually or connecting to an integration like Anvyl or NetSuite."
            )}
          </Typography>

          <Link to={`/${teamCtx.team.slug}/settings/integrations`}>
            {t("Settings/Integrations")}
          </Link>

          <AspectRatio
            variant="plain"
            ratio={1}
            objectFit="contain"
            maxHeight={"50vh"}
            sx={{ bgcolor: "transparent" }}
          >
            <img
              alt={t("No Inspections yet")}
              src="/images/inspections_empty_state.png"
            />
          </AspectRatio>
        </>
      </Box>
    </>
  );
}

import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import { useTranslation } from "../../../i18n";
import { useTeam } from "../TeamLayout";
import { AspectRatio, Button } from "@mui/joy";
import { Link } from "react-router-dom";

export default function PurchaseOrders() {
  const t = useTranslation();
  const teamCtx = useTeam();

  return (
    <>
      <Box sx={{ display: "flex", m: 2 }}>
        <Typography sx={{ flex: 1 }} level="h2" component="h1">
          {t("Purchase Orders")}
        </Typography>
        <Button variant="solid">{t("Add Purchase Order")}</Button>
      </Box>
      <Box
        sx={{
          gap: 2,
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <>
          <Typography color="neutral" level="body-md">
            No Purchase Orders yet! Start by adding one manually or connecting
            to an integration like Anvyl or NetSuite.
          </Typography>

          <Link to={`/${teamCtx.team.slug}/settings/integrations`}>
            Settings/Integrations
          </Link>

          <AspectRatio
            variant="plain"
            ratio={1}
            objectFit="contain"
            maxHeight={"50vh"}
            sx={{ bgcolor: "transparent" }}
          >
            <img
              alt="No purchase orders yet"
              src="/images/purchase_orders_empty_state.png"
            />
          </AspectRatio>
        </>
      </Box>
    </>
  );
}

import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import { useTranslation } from "../../../i18n";
import { Button } from "@mui/joy";

export default function Files() {
  const t = useTranslation();

  return (
    <>
      <Box sx={{ display: "flex", m: 2 }}>
        <Typography sx={{ flex: 1 }} level="h2" component="h1">
          {t("Files")}
        </Typography>
        <Button variant="solid">{t("Add File")}</Button>
      </Box>
      <Box
        sx={{
          gap: 2,
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <>
          <Typography color="neutral" level="body-md">
            No Files yet!
          </Typography>
        </>
      </Box>
    </>
  );
}

import { BehaviorSubject, combineLatest, map, Subject } from "rxjs";
import { Supplier } from "../../../../__generated__/types-and-hooks";
import { computed } from "../../../../decorators/computed";

export interface IDraft {
  serviceType: string;
  serviceDate: Date | null;
  serviceManDays: number | null;
  rate: [number, string] | null;
  supplier: Supplier | null;
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  postalCode: string;
  state: string;
  samplings: any;
  notes: string | null;
}

type WithSubjects<T> = {
  [K in keyof T as `${K & string}$`]: Subject<T[K]>;
};

export class DraftVM implements WithSubjects<IDraft> {
  public serviceType$: Subject<string>;
  public serviceDate$: Subject<Date | null>;
  public serviceManDays$: Subject<number | null>;
  public rate$: Subject<[number, string] | null>;
  public supplier$: Subject<Supplier | null>;
  public addressLine1$: Subject<string>;
  public addressLine2$: Subject<string>;
  public city$: Subject<string>;
  public country$: Subject<string>;
  public postalCode$: Subject<string>;
  public state$: Subject<string>;
  public samplings$: Subject<any>;
  public notes$: Subject<string | null>;

  constructor(draft: IDraft) {
    this.serviceType$ = new BehaviorSubject(draft.serviceType);
    this.serviceDate$ = new BehaviorSubject(draft.serviceDate);
    this.serviceManDays$ = new BehaviorSubject(draft.serviceManDays);
    this.rate$ = new BehaviorSubject(draft.rate);
    this.supplier$ = new BehaviorSubject(draft.supplier);
    this.addressLine1$ = new BehaviorSubject(draft.addressLine1);
    this.addressLine2$ = new BehaviorSubject(draft.addressLine2);
    this.city$ = new BehaviorSubject(draft.city);
    this.country$ = new BehaviorSubject(draft.country);
    this.postalCode$ = new BehaviorSubject(draft.postalCode);
    this.state$ = new BehaviorSubject(draft.state);
    this.samplings$ = new BehaviorSubject(draft.samplings);
    this.notes$ = new BehaviorSubject(draft.notes);
  }

  @computed
  get json$() {
    return combineLatest({
      serviceType: this.serviceType$,
      serviceDate: this.serviceDate$,
      serviceManDays: this.serviceManDays$,
      rate: this.rate$,
      supplier: this.supplier$,
      addressLine1: this.addressLine1$,
      addressLine2: this.addressLine2$,
      city: this.city$,
      country: this.country$,
      postalCode: this.postalCode$,
      state: this.state$,
      samplings: this.samplings$,
      notes: this.notes$,
    });
  }

  @computed
  get addressStr$() {
    return combineLatest({
      addressLine1: this.addressLine1$,
      addressLine2: this.addressLine2$,
      city: this.city$,
      state: this.state$,
      postalCode: this.postalCode$,
      country: this.country$,
    }).pipe(
      map((data) => {
        return [
          data.addressLine1,
          data.addressLine2,
          //data.district,
          [data.city, data.state].join(", ") + " " + data.postalCode,
          data.country,
        ].join("\n");
      })
    );
  }
}

import TeamLayout from "./TeamLayout";
import Inspections from "./inspections/Inspections";
import PurchaseOrders from "./purchase-orders/PurchaseOrders";
import API from "./settings/API";
import AnvylConfigurationModal from "./settings/AnvylConfigurationModal";
import General from "./settings/General";
import Integrations from "./settings/Integrations";
import InviteMemberModal from "./settings/InviteMemberModal";
import Members from "./members/Members";
import Settings from "./settings/Settings";
import Skus from "./skus/Skus";
import Suppliers from "./suppliers/Suppliers";
import Chat from "./chats/Chat";
import Files from "./files/Files";
import { inspectionRoutes } from "./inspections/routes";

export const teamRoutes = {
  path: "/:teamSlug",
  element: <TeamLayout />,
  children: [
    ...inspectionRoutes,
    {
      path: "/:teamSlug",
      element: <Inspections />,
      children: [],
    },
    {
      path: "/:teamSlug/suppliers",
      element: <Suppliers />,
      children: [],
    },
    {
      path: "/:teamSlug/sku",
      element: <Skus />,
      children: [],
    },
    {
      path: "/:teamSlug/purchase_orders",
      element: <PurchaseOrders />,
      children: [],
    },
    {
      path: "/:teamSlug/members",
      element: <Members />,
      children: [],
    },
    {
      path: "/:teamSlug/chat",
      element: <Chat />,
      children: [],
    },
    {
      path: "/:teamSlug/files",
      element: <Files />,
      children: [],
    },
    {
      path: "/:teamSlug/agencies",
      element: <h1>Agencies</h1>,
      children: [],
    },
    // {
    //   path: "/:teamSlug/inspections",
    //   element: <Inspections />,
    //   children: [
    //     {
    //       path: "/:teamSlug/inspections",
    //       element: <Bids />,
    //     },
    //     {
    //       path: "/:teamSlug/inspections/bids",
    //       element: <Bids />,
    //     },
    //     {
    //       path: "/:teamSlug/inspections/bids/new",
    //       element: <CreateBid />,
    //     },
    //     {
    //       path: "/:teamSlug/inspections/in-progress",
    //       element: <div>In-Progress</div>,
    //     },
    //     {
    //       path: "/:teamSlug/inspections/completed",
    //       element: <div>Completed</div>,
    //     },
    //   ],
    // },
    {
      path: "/:teamSlug/settings",
      element: <Settings />,
      children: [
        {
          path: "/:teamSlug/settings",
          element: <General />,
        },
        {
          path: "/:teamSlug/settings/general",
          element: <General />,
        },
        {
          path: "/:teamSlug/settings/integrations",
          element: (
            <>
              <Integrations />
              <AnvylConfigurationModal open={false} />
            </>
          ),
        },
        {
          path: "/:teamSlug/settings/integrations/anvyl",
          element: (
            <>
              <Integrations />
              <AnvylConfigurationModal open={true} />
            </>
          ),
        },
        {
          path: "/:teamSlug/settings/members",
          element: (
            <>
              <Members />
              <InviteMemberModal open={false} />
            </>
          ),
        },
        {
          path: "/:teamSlug/settings/members/invite",
          element: (
            <>
              <Members />
              <InviteMemberModal open={true} />
            </>
          ),
        },
        {
          path: "/:teamSlug/settings/api",
          element: (
            <>
              <API />
            </>
          ),
        },
      ],
    },
  ],
};

import Button from "@mui/joy/Button";
import { Alert, FormControl, FormLabel } from "@mui/joy";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import { Typography } from "@mui/joy";
import { Stack } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import React, { useCallback } from "react";
import { useTranslation } from "../../../i18n";
import {
  TeamSearchFragment,
  useTeamJoinMutation,
} from "../../../__generated__/types-and-hooks";
import { QCSKULogo } from "../../../components/QCSKULogo";
import { XTeamsAutocomplete } from "../../../x-components/XTeamsAutocomplete";
import { BehaviorSubject } from "rxjs";
import { useRxVal } from "../../../hooks/useRx";

export default function JoinTeam() {
  const navigate = useNavigate();
  const t = useTranslation();
  const team$ = React.useMemo(
    () => new BehaviorSubject<TeamSearchFragment | null>(null),
    []
  );
  const team = useRxVal(team$);
  const [teamJoinMutation, { loading, error }] = useTeamJoinMutation();
  const handleJoinTeam = useCallback(async () => {
    if (team) {
      await teamJoinMutation({
        variables: { teamId: team.id },
      });
      navigate("/next-steps", {
        state: {
          text: `You have successfully submitted a request to join ${team?.name}`,
        },
      });
    }
  }, [teamJoinMutation, team, navigate]);

  return (
    <>
      <Stack gap={4} sx={{ mb: 2 }}>
        <Stack gap={1}>
          <Card
            variant="outlined"
            sx={{
              boxShadow: "sm",
              textAlign: "center",
              alignItems: "center",
              width: 343,
              margin: "auto",
              // to make the demo resizable
              overflow: "auto",
              "--icon-size": "100px",
              bgcolor: "background.surface",
            }}
          >
            <CardOverflow variant="soft">
              <QCSKULogo />
            </CardOverflow>
            <Typography
              level="title-lg"
              sx={{ mt: "calc(var(--icon-size) / 2)" }}
            >
              {t("Find Brand or Agency")}
            </Typography>
            <CardContent>
              <FormControl>
                <FormLabel>
                  <Typography
                    color="neutral"
                    level="body-xs"
                    sx={{ opacity: "70%", textAlign: "left" }}
                  >
                    {t(
                      "Start typing a name of the brand or agency that you want to join."
                    )}
                  </Typography>
                </FormLabel>
                <XTeamsAutocomplete
                  val$={team$}
                  placeholder={"Type to search"}
                />
              </FormControl>
            </CardContent>
            <CardActions
              orientation="vertical"
              buttonFlex={1}
              sx={{
                "--Button-radius": "40px",
                width: "clamp(min(100%, 200px), 50%, min(100%, 200px))",
              }}
            >
              <Button
                loading={loading}
                variant={"solid"}
                color={"primary"}
                disabled={team === null}
                onClick={handleJoinTeam}
              >
                {t("Join")}
              </Button>
              <Button
                variant="plain"
                color="primary"
                onClick={() => navigate("/")}
              >
                {t("Go Back")}
              </Button>
            </CardActions>
            {error && (
              <Alert color="danger">
                {error?.message ?? "Error. Please try again later."}
              </Alert>
            )}
          </Card>
        </Stack>
      </Stack>
    </>
  );
}

import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";

export function InlineIcon() {
  return <PrecisionManufacturingIcon />;
}

export const InlineLabel = "Inline Inspection";
export const InlineLabelShort = "Inline";

export const InlineValue = "INLINE";

export const InlineDescription = "Conducted while production is in progress";

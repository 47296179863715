import React from "react";
import { BehaviorSubject } from "rxjs";
import { Live } from "../models/live";

export const LiveContext = React.createContext<{ live: Live } | null>(null);

export type LiveProviderProps = {
  children: React.ReactNode;
  wss$: BehaviorSubject<string | null>;
  inputWss$: BehaviorSubject<string | null>;
};

export function LiveProvider({ children, wss$, inputWss$ }: LiveProviderProps) {
  return (
    <LiveContext.Provider value={{ live: new Live(wss$, inputWss$) }}>
      {children}
    </LiveContext.Provider>
  );
}

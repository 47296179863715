import * as React from "react";
import Button from "@mui/joy/Button";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import { useNavigate } from "react-router-dom";
import { AspectRatio } from "@mui/joy";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import CheckIcon from "@mui/icons-material/Check";
import Confetti from "react-confetti";

export function CongratCard() {
  const navigate = useNavigate();
  return (
    <Card
      // variant="soft"
      data-resizable
      sx={{
        textAlign: "center",
        alignItems: "center",
        width: 343,
        // to make the demo resizable
        overflow: "auto",
        "--icon-size": "100px",
        bgcolor: "background.surface",
      }}
    >
      <CardOverflow variant="soft">
        <AspectRatio
          variant="outlined"
          ratio="1"
          sx={{
            m: "auto",
            transform: "translateY(50%)",
            borderRadius: "50%",
            width: "var(--icon-size)",
            boxShadow: "sm",
            bgcolor: "background.surface",
            position: "relative",
          }}
        >
          <CheckIcon color="success" sx={{ fontSize: "4rem" }} />
        </AspectRatio>
      </CardOverflow>
      <Typography level="title-lg" sx={{ mt: "calc(var(--icon-size) / 2)" }}>
        You're In!
      </Typography>
      <CardContent sx={{ maxWidth: "40ch" }}>
        <Typography>
          Don't forget to check your email to verify your account and start
          exploring!
        </Typography>
        <Typography level="body-sm">
          Let your friends know you're part of our community!
        </Typography>
      </CardContent>
      <CardActions
        orientation="vertical"
        buttonFlex={1}
        sx={{
          "--Button-radius": "40px",
          width: "clamp(min(100%, 160px), 50%, min(100%, 200px))",
        }}
      >
        <Button
          variant="solid"
          color="primary"
          onClick={() => navigate("/auth/sign-in")}
        >
          Spread the Word
        </Button>
        <Button
          variant="plain"
          color="neutral"
          onClick={() => navigate("/auth/sign-in")}
        >
          Maybe Later
        </Button>
      </CardActions>
    </Card>
  );
}

export default function NextSteps() {
  const [numberOfPieces, setNumberOfPieces] = React.useState(200);

  React.useEffect(() => {
    setTimeout(() => setNumberOfPieces(0), 2000);
  }, []);

  return (
    <>
      <Confetti numberOfPieces={numberOfPieces} />

      <Stack gap={4} sx={{ mb: 2 }}>
        <Stack gap={1}>
          <CongratCard />
        </Stack>
      </Stack>
    </>
  );
}

import OpenInNewRounded from "@mui/icons-material/OpenInNewRounded";
import {
  Box,
  Button,
  Card,
  CardActions,
  Divider,
  Link,
  Stack,
  Typography,
} from "@mui/joy";
import { ReactNode } from "react";

export interface ConfigurationCardProps {
  title: string;
  link?: string;
  children: ReactNode;
  status?: ReactNode;
  onClick: () => void;
  buttonStartDecorator?: ReactNode;
  buttonText: string;
  disabled?: boolean;
}

export function ConfigurationCard({
  title,
  link,
  children,
  status,
  onClick,
  buttonText,
  buttonStartDecorator,
  disabled,
}: ConfigurationCardProps) {
  return (
    <Card size="lg" variant="outlined" sx={{ boxShadow: "sm" }}>
      <Typography level="title-lg">{title}</Typography>
      {link && (
        <Typography
          level="body-xs"
          sx={{
            position: "absolute",
            top: "1.375rem",
            right: "0.5rem",
            p: 1,
          }}
        >
          <Link href={link} target="_blank" color="primary">
            {link}
            <OpenInNewRounded style={{ fontSize: 12 }} />
          </Link>
        </Typography>
      )}
      <Divider inset="none" />
      <Box maxHeight={100} minHeight={100} gap={1}>
        {children}
      </Box>
      <Divider inset="none" />
      <CardActions>
        <Stack
          direction="row"
          gap={1}
          justifyContent="space-between"
          width="100%"
        >
          {status}
          <Button
            variant="soft"
            color="neutral"
            startDecorator={buttonStartDecorator}
            onClick={onClick}
            disabled={disabled}
          >
            {buttonText}
          </Button>
        </Stack>
      </CardActions>
    </Card>
  );
}

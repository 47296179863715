import React from "react";
import Button from "@mui/joy/Button";
import Stack from "@mui/joy/Stack";
import FactoryIcon from "@mui/icons-material/FactoryRounded";
import TodayRoundedIcon from "@mui/icons-material/TodayRounded";
import MemoryIcon from "@mui/icons-material/MemoryRounded";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLongRounded";
import Layout from "../../components/Layout";
import Navigation from "../../components/Navigation";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { useTeamInfoQuery } from "../../__generated__/types-and-hooks";
import {
  Avatar,
  Box,
  CircularProgress,
  DialogTitle,
  Drawer,
  IconButton,
  ModalClose,
  Typography,
} from "@mui/joy";
import { useTranslation } from "../../i18n";
// import SearchBar from "../../components/header/SearchBar";
// import NotificationsDropDown from "../../components/header/NotificationsDropDown";
import LanguageToggle from "../../components/header/LanguageToggle";
import ColorSchemeToggle from "../../components/header/ColorSchemeToggle";
import ProfileDropDown from "../../components/header/ProfileDropDown";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import HomeIcon from "@mui/icons-material/Home";
import PageNotFound from "../PageNotFound";
import SupportAgentIcon from "@mui/icons-material/SupportAgentRounded";

export interface TeamContext {
  team: Exclude<
    Exclude<
      ReturnType<typeof useTeamInfoQuery>["data"],
      undefined
    >["team_by_slug"],
    undefined | null
  >;
}

export function useTeam() {
  return useOutletContext<TeamContext>();
}

export default function TeamLayout() {
  const t = useTranslation();
  const navigate = useNavigate();
  const { teamSlug } = useParams();
  const { pathname } = useLocation();
  const [, section] = pathname.split("/").slice(1);
  const teamInfoQuery = useTeamInfoQuery({ variables: { slug: teamSlug! } });
  const [open, setOpen] = React.useState(false);

  const team = teamInfoQuery.data?.team_by_slug;
  if (!teamInfoQuery.loading && !team) {
    return <PageNotFound />;
  }

  // if (team?.kind === "AGENCY") {
  //   return <div>Agency</div>;
  // }

  return (
    <>
      <Stack
        id="tab-bar"
        direction="row"
        justifyContent="space-around"
        spacing={1}
        sx={{
          display: { xs: "flex", sm: "none" },
          zIndex: "999",
          bottom: 0,
          position: "fixed",
          width: "100dvw",
          p: 2,
          // backgroundColor: "background.body",
          backdropFilter: "blur(10px)",
          borderTop: "1px solid",
          borderColor: "divider",
        }}
      >
        <Button
          variant={!section || section === "inspections" ? "soft" : "plain"}
          color="neutral"
          component={Link}
          to={`/${teamSlug}/inspections`}
          size="sm"
          startDecorator={<TodayRoundedIcon fontSize="medium" />}
          sx={{ width: "100%", flexDirection: "column", "--Button-gap": 0 }}
        >
          {t("Inspections")}
        </Button>

        {team?.kind === "BRAND" && (
          <Button
            variant={section === "sku" ? "soft" : "plain"}
            color="neutral"
            component={Link}
            to={`/${teamSlug}/sku`}
            size="sm"
            startDecorator={<MemoryIcon fontSize="medium" />}
            sx={{ width: "100%", flexDirection: "column", "--Button-gap": 0 }}
          >
            {t("SKUs")}
          </Button>
        )}

        {team?.kind === "BRAND" && (
          <Button
            variant={section === "purchase_orders" ? "soft" : "plain"}
            color="neutral"
            component={Link}
            to={`/${teamSlug}/purchase_orders`}
            size="sm"
            startDecorator={<ReceiptLongIcon fontSize="medium" />}
            sx={{ width: "100%", flexDirection: "column", "--Button-gap": 0 }}
          >
            {t("POs")}
          </Button>
        )}

        {team?.kind === "BRAND" && (
          <Button
            variant={section === "suppliers" ? "soft" : "plain"}
            color="neutral"
            component={Link}
            to={`/${teamSlug}/suppliers`}
            size="sm"
            startDecorator={<FactoryIcon fontSize="medium" />}
            sx={{ width: "100%", flexDirection: "column", "--Button-gap": 0 }}
          >
            {t("Suppliers")}
          </Button>
        )}

        {team?.kind === "AGENCY" && (
          <Button
            variant={section === "messaging" ? "soft" : "plain"}
            color="neutral"
            component={Link}
            to={`/${teamSlug}/chat`}
            size="sm"
            startDecorator={<SupportAgentIcon fontSize="medium" />}
            sx={{ width: "100%", flexDirection: "column", "--Button-gap": 0 }}
          >
            {t("Messaging")}
          </Button>
        )}
      </Stack>
      <Layout.Root>
        <Layout.Header
          sx={{
            bgcolor:
              team?.kind === "AGENCY" ? "danger.softBg" : "primary.softBg",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              justifyContent: "space-between",
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
              sx={{ display: { xs: "none", sm: "flex" } }}
            >
              <IconButton
                variant="soft"
                size="md"
                onClick={() => navigate("/")}
              >
                <HomeIcon />
              </IconButton>
              <Typography
                startDecorator={
                  teamInfoQuery.data?.team_by_slug?.picture && (
                    <Avatar
                      variant="soft"
                      sx={{
                        maxWidth: "40px",
                        maxHeight: "40px",
                      }}
                      src={teamInfoQuery.data?.team_by_slug?.picture}
                    />
                  )
                }
              >
                {teamInfoQuery.data?.team_by_slug?.name}
              </Typography>
            </Stack>
            <Box sx={{ display: { xs: "inline-flex", sm: "none" } }}>
              <IconButton
                variant="plain"
                color="neutral"
                onClick={() => setOpen(true)}
              >
                <MenuRoundedIcon />
              </IconButton>

              <Typography
                sx={{ lineHeight: "40px" }}
                startDecorator={
                  teamInfoQuery.data?.team_by_slug?.picture && (
                    <Avatar
                      variant="soft"
                      sx={{
                        maxWidth: "40px",
                        maxHeight: "40px",
                      }}
                      src={teamInfoQuery.data?.team_by_slug?.picture}
                    />
                  )
                }
                endDecorator={
                  <IconButton
                    variant="soft"
                    size="md"
                    onClick={() => navigate("/")}
                  >
                    <HomeIcon />
                  </IconButton>
                }
              >
                {teamInfoQuery.data?.team_by_slug?.name}
              </Typography>
              <Drawer
                sx={{ display: { xs: "inline-flex", sm: "none" } }}
                open={open}
                onClose={() => setOpen(false)}
              >
                <ModalClose />
                <DialogTitle>
                  {teamInfoQuery.data?.team_by_slug?.name}
                </DialogTitle>
                <Box sx={{ px: 1 }}>
                  {team && (
                    <Navigation teamKind={team.kind as "BRAND" | "AGENCY"} />
                  )}
                </Box>
              </Drawer>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1.5,
                alignItems: "center",
              }}
            >
              {/* <SearchBar />
              <NotificationsDropDown /> */}
              <LanguageToggle />
              <ColorSchemeToggle />
              <ProfileDropDown />
            </Box>
          </Box>
        </Layout.Header>
        <Layout.SideNav>
          {team && <Navigation teamKind={team.kind as "BRAND" | "AGENCY"} />}
        </Layout.SideNav>
        <Layout.Main sx={{ mb: 10, overflow: "auto" }}>
          {teamInfoQuery.loading && <CircularProgress size="lg" />}
          {teamInfoQuery.data?.team_by_slug && (
            <Outlet
              context={
                {
                  team: teamInfoQuery.data?.team_by_slug,
                } satisfies TeamContext & any
              }
            />
          )}
        </Layout.Main>
      </Layout.Root>
    </>
  );
}

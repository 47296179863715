import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListItemContent from "@mui/joy/ListItemContent";
import TodayRoundedIcon from "@mui/icons-material/TodayRounded";
import { Divider } from "@mui/joy";
import FactoryIcon from "@mui/icons-material/FactoryRounded";
import MemoryIcon from "@mui/icons-material/MemoryRounded";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLongRounded";
import GroupsIcon from "@mui/icons-material/GroupsRounded";
import SupportAgentIcon from "@mui/icons-material/SupportAgentRounded";
import SettingsIcon from "@mui/icons-material/SettingsRounded";
import { Link, useLocation, useParams } from "react-router-dom";
import Tooltip from "@mui/joy/Tooltip";
import { useTranslation } from "../i18n";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";

export interface NavigationProps {
  teamKind: "AGENCY" | "BRAND";
}

export default function Navigation({ teamKind }: NavigationProps) {
  const t = useTranslation();
  const { teamSlug } = useParams();
  const { pathname } = useLocation();
  const [, section] = pathname.split("/").slice(1);

  return (
    <List
      size="sm"
      sx={{ "--ListItem-radius": "var(--joy-radius-sm)", "--List-gap": "4px" }}
    >
      <ListItem nested>
        <List
          aria-labelledby="nav-list-browse"
          sx={{
            "& .JoyListItemButton-root": { p: "8px" },
          }}
        >
          <ListItem>
            <ListItemButton
              component={Link}
              selected={section === "inspections"}
              to={`/${teamSlug}/inspections`}
            >
              <ListItemDecorator>
                <Tooltip
                  sx={{ display: { xs: "none", sm: "block", lg: "none" } }}
                  title={t("Inspections")}
                  placement="right"
                  size="md"
                  variant="solid"
                >
                  <TodayRoundedIcon fontSize="small" />
                </Tooltip>
                <TodayRoundedIcon
                  fontSize="small"
                  sx={{ display: { xs: "flex", sm: "none", lg: "flex" } }}
                />
              </ListItemDecorator>
              <ListItemContent
                sx={{ display: { xs: "flex", sm: "none", lg: "flex" } }}
              >
                {t("Inspections")}
              </ListItemContent>
            </ListItemButton>
          </ListItem>
          {teamKind === "BRAND" && (
            <ListItem>
              <ListItemButton
                component={Link}
                selected={section === "sku"}
                to={`/${teamSlug}/sku`}
              >
                <ListItemDecorator>
                  <MemoryIcon fontSize="small" />
                </ListItemDecorator>
                <ListItemContent sx={{ display: { sm: "none", lg: "flex" } }}>
                  {t("SKU")}
                </ListItemContent>
              </ListItemButton>
            </ListItem>
          )}
          {teamKind === "BRAND" && (
            <ListItem>
              <ListItemButton
                component={Link}
                selected={section === "purchase_orders"}
                to={`/${teamSlug}/purchase_orders`}
              >
                <ListItemDecorator>
                  <ReceiptLongIcon fontSize="small" />
                </ListItemDecorator>
                <ListItemContent sx={{ display: { sm: "none", lg: "flex" } }}>
                  {t("PO")}
                </ListItemContent>
              </ListItemButton>
            </ListItem>
          )}
          {teamKind === "BRAND" && (
            <ListItem>
              <ListItemButton
                component={Link}
                selected={section === "suppliers"}
                to={`/${teamSlug}/suppliers`}
              >
                <ListItemDecorator>
                  <FactoryIcon fontSize="small" />
                </ListItemDecorator>
                <ListItemContent sx={{ display: { sm: "none", lg: "flex" } }}>
                  {t("Suppliers")}
                </ListItemContent>
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </ListItem>
      <ListItem nested>
        <Divider sx={{ display: { sm: "none", lg: "flex" } }}>
          Operations
        </Divider>
        <List
          aria-labelledby="nav-list-browse"
          sx={{
            "& .JoyListItemButton-root": { p: "8px" },
          }}
        >
          <ListItem>
            <ListItemButton
              component={Link}
              selected={section === "files"}
              to={`/${teamSlug}/files`}
            >
              <ListItemDecorator>
                <FolderOpenIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent sx={{ display: { sm: "none", lg: "flex" } }}>
                {t("Files")}
              </ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              component={Link}
              selected={section === "chat"}
              to={`/${teamSlug}/chat`}
            >
              <ListItemDecorator>
                <SupportAgentIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent sx={{ display: { sm: "none", lg: "flex" } }}>
                {t("Messaging")}
              </ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              component={Link}
              selected={section === "members"}
              to={`/${teamSlug}/members`}
            >
              <ListItemDecorator>
                <GroupsIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent sx={{ display: { sm: "none", lg: "flex" } }}>
                {t("Members")}
              </ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              component={Link}
              selected={section === "settings"}
              to={`/${teamSlug}/settings`}
            >
              <ListItemDecorator>
                <SettingsIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent sx={{ display: { sm: "none", lg: "flex" } }}>
                {t("Settings")}
              </ListItemContent>
            </ListItemButton>
          </ListItem>
        </List>
      </ListItem>
    </List>
  );
}

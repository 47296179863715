import { useState } from "react";
import { DraftVM } from "./DraftVM";
import {
  DataGrid,
  GridColDef,
  GridColumnGroupingModel,
  GridRenderEditCellParams,
  GridActionsCellItem,
  GridRowParams,
  useGridApiContext,
} from "@mui/x-data-grid";
import { SkuAutocomplete } from "../../../../components/SkuAutocomplete";
import { useTeam } from "../../TeamLayout";
import { Button, Typography } from "@mui/joy";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Table from "@mui/joy/Table";
import { useTranslation } from "../../../../i18n";

export type DefectsAQL = {
  aql: number;
  sampleSize: number;
  acceptPoint: number;
  rejectPoint: number;
};

export type Sample = {
  id: string | number;
  sku: string;
  quantity: number;
  inspectinLevel: string;
  criticalDefects: DefectsAQL;
  majorDefects: DefectsAQL;
  minorDefects: DefectsAQL;
};

const columnGroupingModel: GridColumnGroupingModel = [
  {
    groupId: "Critical Defects",
    description: "",
    children: [
      { field: "criticalDefectsAQL" },
      { field: "criticalDefectsSampleSize" },
      { field: "criticalDefectsAcceptPoint" },
      { field: "criticalDefectsRejectPoint" },
    ],
  },
  {
    groupId: "Major Defects",
    description: "",
    children: [
      { field: "majorDefectsAQL" },
      { field: "majorDefectsSampleSize" },
      { field: "majorDefectsAcceptPoint" },
      { field: "majorDefectsRejectPoint" },
    ],
  },
  {
    groupId: "Minor Defects",
    description: "",
    children: [
      { field: "minorDefectsAQL" },
      { field: "minorDefectsSampleSize" },
      { field: "minorDefectsAcceptPoint" },
      { field: "minorDefectsRejectPoint" },
    ],
  },
];

let id = 0;

export default function StepSampling({ vm }: { vm: DraftVM }) {
  const t = useTranslation();
  const emptyLine = {
    id: id++,
    sku: "",
    quantity: 100,
    inspectinLevel: "I",
    criticalDefects: {
      aql: 1.2,
      sampleSize: 100,
      acceptPoint: 1,
      rejectPoint: 2,
    },
    majorDefects: {
      aql: 1.2,
      sampleSize: 100,
      acceptPoint: 1,
      rejectPoint: 2,
    },
    minorDefects: {
      aql: 1.2,
      sampleSize: 100,
      acceptPoint: 1,
      rejectPoint: 2,
    },
  };
  const [samples, setSamples] = useState<Sample[]>([]);

  const rows = samples.map((sample) => ({
    ...sample,
    criticalDefectsAQL: sample.criticalDefects.aql,
    criticalDefectsSampleSize: sample.criticalDefects.sampleSize,
    criticalDefectsAcceptPoint: sample.criticalDefects.acceptPoint,
    criticalDefectsRejectPoint: sample.criticalDefects.rejectPoint,
    majorDefectsAQL: sample.majorDefects.aql,
    majorDefectsSampleSize: sample.majorDefects.sampleSize,
    majorDefectsAcceptPoint: sample.majorDefects.acceptPoint,
    majorDefectsRejectPoint: sample.majorDefects.rejectPoint,
    minorDefectsAQL: sample.minorDefects.aql,
    minorDefectsSampleSize: sample.minorDefects.sampleSize,
    minorDefectsAcceptPoint: sample.minorDefects.acceptPoint,
    minorDefectsRejectPoint: sample.minorDefects.rejectPoint,
  }));

  const gridToSample = (data: any) => {
    return {
      id: data.id,
      sku: data.sku,
      quantity: data.quantity,
      inspectinLevel: data.inspectinLevel,
      criticalDefects: {
        aql: data.criticalDefectsAQL,
        sampleSize: data.criticalDefectsSampleSize,
        acceptPoint: data.criticalDefectsAcceptPoint,
        rejectPoint: data.criticalDefectsRejectPoint,
      },
      majorDefects: {
        aql: data.majorDefectsAQL,
        sampleSize: data.majorDefectsSampleSize,
        acceptPoint: data.majorDefectsAcceptPoint,
        rejectPoint: data.majorDefectsRejectPoint,
      },
      minorDefects: {
        aql: data.minorDefectsAQL,
        sampleSize: data.minorDefectsSampleSize,
        acceptPoint: data.minorDefectsAcceptPoint,
        rejectPoint: data.minorDefectsRejectPoint,
      },
    };
  };

  const handleClone = (params: GridRowParams<any>) => {
    const currentSampleIndex = samples.findIndex(
      (sample) => sample.id === params.id
    );
    const clone = { ...samples[currentSampleIndex], id: id++ };

    samples.splice(currentSampleIndex + 1, 0, clone);

    setSamples([...samples]);
  };

  const handleDelete = (params: GridRowParams<any>) => {
    setSamples(samples.filter((sample) => sample.id !== params.id));
  };

  function SKUViewEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();
    const ctx = useTeam();

    return (
      <SkuAutocomplete
        teamId={ctx.team.id}
        sku={value}
        onChange={(sku) => {
          apiRef.current.setEditCellValue({ id, field, value: sku?.sku });
        }}
      />
    );
  }

  const columns: GridColDef[] = [
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeleteForeverIcon />}
          label={t("Delete")}
          onClick={() => handleDelete(params)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<AddCircleOutlineIcon />}
          label={t("Clone")}
          onClick={() => handleClone(params)}
        />,
      ],
    },
    {
      field: "sku",
      headerName: t("SKU"),
      width: 180,
      editable: true,
      renderEditCell: SKUViewEditComponent,
    },
    {
      field: "quantity",
      headerName: t("QTY"),
      width: 80,
      editable: true,
    },
    {
      field: "inspectinLevel",
      headerName: t("IL"),
      width: 80,
      editable: true,
    },
    {
      field: "criticalDefectsAQL",
      headerName: t("AQL"),
      width: 80,
      editable: true,
    },
    {
      field: "criticalDefectsSampleSize",
      headerName: t("Sample Size"),
      width: 80,
      editable: true,
    },
    {
      field: "criticalDefectsAcceptPoint",
      headerName: t("Accept Point"),
      width: 80,
      editable: true,
    },
    {
      field: "criticalDefectsRejectPoint",
      headerName: t("Reject Point"),
      width: 80,
      editable: true,
    },
    {
      field: "majorDefectsAQL",
      headerName: t("AQL"),
      width: 80,
      editable: true,
    },
    {
      field: "majorDefectsSampleSize",
      headerName: t("Sample Size"),
      width: 80,
      editable: true,
    },
    {
      field: "majorDefectsAcceptPoint",
      headerName: t("Accept Point"),
      width: 80,
      editable: true,
    },
    {
      field: "majorDefectsRejectPoint",
      headerName: t("Reject Point"),
      width: 80,
      editable: true,
    },
    {
      field: "minorDefectsAQL",
      headerName: t("AQL"),
      width: 80,
      editable: true,
    },
    {
      field: "minorDefectsSampleSize",
      headerName: t("Sample Size"),
      width: 80,
      editable: true,
    },
    {
      field: "minorDefectsAcceptPoint",
      headerName: t("Accept Point"),
      width: 80,
      editable: true,
    },
    {
      field: "minorDefectsRejectPoint",
      headerName: t("Reject Point"),
      width: 80,
      editable: true,
    },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="cell"
        showColumnVerticalBorder
        showCellVerticalBorder
        rowHeight={40}
        disableVirtualization={false}
        disableColumnMenu
        disableColumnFilter
        disableDensitySelector
        disableMultipleRowSelection
        disableRowSelectionOnClick
        columnGroupingModel={columnGroupingModel}
        columnHeaderHeight={48}
        columnGroupHeaderHeight={24}
        sx={{ border: 0 }}
        slots={{
          noRowsOverlay: () => (
            <div
              style={{
                height: "100%",
                width: "100%",
                margin: "auto",
                textAlign: "center",
              }}
            >
              <Typography level="body-sm" sx={{ m: 4 }}>
                {t("No sampling for this inspection yet.")}
              </Typography>
              <Button
                variant="outlined"
                onClick={() => setSamples([...samples, emptyLine])}
              >
                {t("Add Sampling")}
              </Button>
            </div>
          ),
          footer: () => {
            return (
              <Table aria-label="basic table">
                <thead>
                  <tr>
                    <th style={{ width: "40%" }}>{t("Total SKUs")}</th>
                    <th>{t("Total QTY")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{samples.length}</td>
                    <td>
                      {samples
                        ?.map((s) => {
                          if (typeof s.quantity === "number") return s.quantity;
                          if (typeof s.quantity === "string")
                            return parseInt(s.quantity, 10);
                          return 0;
                        })
                        .concat([0, 0])
                        .reduce((a, b) => a + b)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            );
          },
        }}
        processRowUpdate={(updatedRow, originalRow) => {
          const index = samples.findIndex(
            (sample) => sample.id === updatedRow.id
          );
          if (index !== -1) {
            const copy = [...samples];
            copy[index] = updatedRow;
            setSamples(copy);
            vm.samplings$.next(copy.map(gridToSample));
          }
          return updatedRow;
        }}
      />
    </div>
  );
}

import { Box, Button, IconButton, Stack, Typography } from "@mui/joy";
import { ConfigurationCard } from "./ConfigurationCard";
import { useNavigate } from "react-router-dom";
import { useTeam } from "../TeamLayout";
import SettingsRounded from "@mui/icons-material/SettingsRounded";
import AddRounded from "@mui/icons-material/AddRounded";
import DeleteRounded from "@mui/icons-material/DeleteRounded";

export default function API() {
  const navigate = useNavigate();
  const { team } = useTeam();

  return (
    <Box
      sx={{
        width: "100%",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(min(100%, 300px), 1fr))",
        gap: 2,
        p: 2,
      }}
    >
      <ConfigurationCard
        title="API Key"
        link="https://docs.qcsku.com/api_key"
        onClick={() => navigate("api")}
        buttonText="Edit"
        buttonStartDecorator={<SettingsRounded />}
        status={
          <Button
            variant="soft"
            color="danger"
            startDecorator={<DeleteRounded />}
            onClick={() => {}}
          >
            Revoke
          </Button>
        }
      >
        <Stack gap={1}>
          <Typography level="title-sm">
            Name:{" "}
            <Typography
              noWrap
              textColor="text.tertiary"
              sx={{ fontSize: "sm" }}
            >
              {team.name}
            </Typography>
          </Typography>
          <Typography level="title-sm">
            API Key:{" "}
            <Typography
              noWrap
              textColor="text.tertiary"
              sx={{ fontSize: "sm" }}
            >
              E2VR3...rO6zs
            </Typography>
          </Typography>
          <Typography level="title-sm">
            TTL:{" "}
            <Typography
              noWrap
              textColor="text.tertiary"
              sx={{ fontSize: "sm" }}
            >
              23 Days
            </Typography>
          </Typography>
          <Typography level="title-sm">
            Last Used:{" "}
            <Typography
              noWrap
              textColor="text.tertiary"
              sx={{ fontSize: "sm" }}
            >
              1 Day ago
            </Typography>
          </Typography>
        </Stack>
      </ConfigurationCard>

      <IconButton variant="soft" color="primary">
        <AddRounded />
        <Typography level="body-lg">New API Key</Typography>
      </IconButton>
    </Box>
  );
}

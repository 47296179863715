import CreateDraft from "./CreateDraft";
import Inspections from "./Inspections";

export const inspectionRoutes = [
  {
    path: "/:teamSlug",
    element: <Inspections />,
    children: [],
  },
  {
    path: "/:teamSlug/inspections",
    element: <Inspections />,
    children: [],
  },
  {
    path: "/:teamSlug/inspections/new",
    element: <CreateDraft />,
    children: [],
  },
];

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTimeISO: { input: any; output: any; }
};

export type Aql = {
  __typename?: 'AQL';
  acceptPoint: Scalars['Float']['output'];
  /** AQL */
  aql: Scalars['Float']['output'];
  rejectPoint: Scalars['Float']['output'];
  sampleSize: Scalars['Float']['output'];
};

export type Anvyl = {
  __typename?: 'Anvyl';
  /** Anvyl API Key */
  anvylApiKey: Scalars['String']['output'];
  /** Anvyl Team Id */
  anvylTeamId: Scalars['Float']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  createdBy: Scalars['Float']['output'];
  enabled: Scalars['Boolean']['output'];
  /** Integration Id */
  id: Scalars['Float']['output'];
  /** Team Id */
  teamId: Scalars['Float']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  updatedBy: Scalars['Float']['output'];
};

export type AnvylInput = {
  /** Anvyl API Key. */
  anvylApiKey: Scalars['String']['input'];
  /** Anvyl Team ID. */
  anvylTeamId: Scalars['Float']['input'];
  /** Enabled */
  enabled: Scalars['Boolean']['input'];
  /** Team ID */
  teamId: Scalars['Float']['input'];
};

export type Checklist = {
  __typename?: 'Checklist';
  createdAt: Scalars['DateTimeISO']['output'];
  createdBy: Scalars['Float']['output'];
  /** Sku Id */
  id: Scalars['Float']['output'];
  items: Array<ChecklistItem>;
  /** Name */
  name: Scalars['String']['output'];
  /** Team Id */
  teamId: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  updatedBy?: Maybe<Scalars['Float']['output']>;
};

export type ChecklistItem = {
  __typename?: 'ChecklistItem';
  /** AQL Code */
  aqlCode: Scalars['String']['output'];
  /** Description */
  description: Scalars['String']['output'];
  /** Name */
  name: Scalars['String']['output'];
  /** Pass Criteria */
  passCriteria: Scalars['String']['output'];
};

export type ChecklistList = {
  __typename?: 'ChecklistList';
  checklists: Array<Checklist>;
  /** Next cursor */
  nextCursor?: Maybe<Scalars['Float']['output']>;
};

export type ContactMethod = {
  __typename?: 'ContactMethod';
  id: Scalars['Float']['output'];
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Country = {
  __typename?: 'Country';
  /** Country code, e.g. US */
  code: Scalars['String']['output'];
  /** Name of the country */
  label: Scalars['String']['output'];
  /** Phone extension */
  phone: Scalars['String']['output'];
};

export type CreateInspectionBidInput = {
  /** Inspection Address */
  address?: InputMaybe<InspectionAddressInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  serviceDate?: InputMaybe<ServiceDateRangeInput>;
  serviceType: Scalars['String']['input'];
  /** Supplier Id */
  supplierId: Scalars['Float']['input'];
  /** Brand Team Id */
  teamId: Scalars['Float']['input'];
};

export type CreateTeamInput = {
  /** Team Description. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Brand or Agency. */
  kind: Scalars['String']['input'];
  /** Team Name. */
  name: Scalars['String']['input'];
  /** Avatar picture. */
  picture?: InputMaybe<Scalars['String']['input']>;
  /** Unique slug name for the team */
  slug: Scalars['String']['input'];
};

export type CreateUserInput = {
  bio?: InputMaybe<Scalars['String']['input']>;
  countryCode: Scalars['String']['input'];
  /** Email */
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  picture?: InputMaybe<Scalars['String']['input']>;
  role: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
  verifiedEmail?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InspectionAddress = {
  __typename?: 'InspectionAddress';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type InspectionAddressInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type InspectionBid = {
  __typename?: 'InspectionBid';
  /** Inspection Address */
  address?: Maybe<InspectionAddress>;
  /** Brand */
  brand: Team;
  checklist?: Maybe<Checklist>;
  createdAt: Scalars['DateTimeISO']['output'];
  createdBy: Scalars['Float']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  /** Sku Id */
  id: Scalars['Float']['output'];
  manDays?: Maybe<ManDaysRange>;
  notes?: Maybe<Scalars['String']['output']>;
  rateRange?: Maybe<RateRange>;
  /** Reference Code */
  ref: Scalars['String']['output'];
  samplings: Array<Sampling>;
  serviceDate?: Maybe<ServiceDateRange>;
  serviceType: Scalars['String']['output'];
  /** Supplier */
  supplier?: Maybe<Supplier>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  updatedBy?: Maybe<Scalars['Float']['output']>;
};

export type InspectionBidList = {
  __typename?: 'InspectionBidList';
  bids: Array<InspectionBid>;
  /** Next cursor */
  nextCursor?: Maybe<Scalars['Float']['output']>;
};

export type InventoryAddress = {
  __typename?: 'InventoryAddress';
  address_line1?: Maybe<Scalars['String']['output']>;
  address_line2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  postal_code?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type InventoryContact = {
  __typename?: 'InventoryContact';
  /** Supplier Address */
  address?: Maybe<InventoryAddress>;
  contactMethods: Array<InventoryContactMethod>;
  createdAt: Scalars['DateTimeISO']['output'];
  createdBy: Scalars['Float']['output'];
  /** Supplier Id */
  id: Scalars['Float']['output'];
  /** Supplier Name */
  name: Scalars['String']['output'];
  sources: Array<InventorySource>;
  updatedAt: Scalars['DateTimeISO']['output'];
  updatedBy: Scalars['Float']['output'];
};

export type InventoryContactMethod = {
  __typename?: 'InventoryContactMethod';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type InventorySource = {
  __typename?: 'InventorySource';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ManDaysRange = {
  __typename?: 'ManDaysRange';
  /** Approximate Man Days */
  from: Scalars['Float']['output'];
  /** Approximate Man Days */
  to?: Maybe<Scalars['Float']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  anvyl_config_update: Anvyl;
  inspection_bid_create: InspectionBid;
  team_assign_user?: Maybe<Team>;
  team_create: Team;
  team_delete?: Maybe<Team>;
  team_join?: Maybe<TeamJoinRequest>;
  team_update: Team;
  user_create: User;
  user_delete?: Maybe<User>;
  user_update: User;
};


export type MutationAnvyl_Config_UpdateArgs = {
  anvyl: AnvylInput;
};


export type MutationInspection_Bid_CreateArgs = {
  bid: CreateInspectionBidInput;
};


export type MutationTeam_Assign_UserArgs = {
  roles: Array<Scalars['String']['input']>;
  teamId: Scalars['Float']['input'];
  userId: Scalars['Float']['input'];
};


export type MutationTeam_CreateArgs = {
  team: CreateTeamInput;
};


export type MutationTeam_DeleteArgs = {
  id: Scalars['Float']['input'];
};


export type MutationTeam_JoinArgs = {
  teamId: Scalars['Float']['input'];
};


export type MutationTeam_UpdateArgs = {
  team: UpdateTeamInput;
};


export type MutationUser_CreateArgs = {
  user: CreateUserInput;
};


export type MutationUser_DeleteArgs = {
  id: Scalars['Float']['input'];
};


export type MutationUser_UpdateArgs = {
  user: UpdateUserInput;
};

export type Query = {
  __typename?: 'Query';
  anvyl_config?: Maybe<Anvyl>;
  checklist_list?: Maybe<ChecklistList>;
  country_list?: Maybe<Array<Country>>;
  inspection_bid_list?: Maybe<InspectionBidList>;
  sku_list?: Maybe<SkuList>;
  supplier_list?: Maybe<SupplierList>;
  team_by_id?: Maybe<Team>;
  team_by_slug?: Maybe<Team>;
  team_list?: Maybe<TeamList>;
  user_by_email?: Maybe<User>;
  user_by_id?: Maybe<User>;
  user_list?: Maybe<UserList>;
  user_me: User;
};


export type QueryAnvyl_ConfigArgs = {
  teamId: Scalars['Float']['input'];
};


export type QueryChecklist_ListArgs = {
  cursor?: InputMaybe<Scalars['Float']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  teamId: Scalars['Float']['input'];
};


export type QueryInspection_Bid_ListArgs = {
  cursor?: InputMaybe<Scalars['Float']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  teamId: Scalars['Float']['input'];
};


export type QuerySku_ListArgs = {
  cursor?: InputMaybe<Scalars['Float']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  teamId: Scalars['Float']['input'];
};


export type QuerySupplier_ListArgs = {
  cursor?: InputMaybe<Scalars['Float']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  teamId: Scalars['Float']['input'];
};


export type QueryTeam_By_IdArgs = {
  id: Scalars['Float']['input'];
};


export type QueryTeam_By_SlugArgs = {
  slug: Scalars['String']['input'];
};


export type QueryTeam_ListArgs = {
  cursor?: InputMaybe<Scalars['Float']['input']>;
  filterBy?: InputMaybe<Scalars['String']['input']>;
  filterExp?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUser_By_EmailArgs = {
  email: Scalars['String']['input'];
};


export type QueryUser_By_IdArgs = {
  id: Scalars['Float']['input'];
};


export type QueryUser_ListArgs = {
  cursor?: InputMaybe<Scalars['Float']['input']>;
  filterBy?: InputMaybe<Scalars['String']['input']>;
  filterExp?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<Scalars['String']['input']>;
};

export type RateRange = {
  __typename?: 'RateRange';
  /** Approximate Rate */
  from: Scalars['Float']['output'];
  /** Approximate Rate */
  to?: Maybe<Scalars['Float']['output']>;
};

export type Sampling = {
  __typename?: 'Sampling';
  criticalDefects: Aql;
  inspectinLevel: Scalars['String']['output'];
  majorDefects: Aql;
  minorDefects: Aql;
  notes: Scalars['String']['output'];
  quantity: Scalars['Float']['output'];
  /** Sku */
  sku?: Maybe<Sku>;
};

export type ServiceDateRange = {
  __typename?: 'ServiceDateRange';
  /** Approximate Service Date */
  from: Scalars['DateTimeISO']['output'];
  /** Approximate Service Date Range */
  to?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type ServiceDateRangeInput = {
  /** Approximate Service Date */
  from: Scalars['DateTimeISO']['input'];
  /** Approximate Service Date Range */
  to?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type Sku = {
  __typename?: 'Sku';
  /** ASIN code */
  asin?: Maybe<Scalars['String']['output']>;
  /** Category */
  category?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  createdBy: Scalars['Float']['output'];
  /** Sku Description */
  description?: Maybe<Scalars['String']['output']>;
  /** HS code */
  hs?: Maybe<Scalars['String']['output']>;
  /** HTS code */
  hts?: Maybe<Scalars['String']['output']>;
  /** Sku Id */
  id: Scalars['Float']['output'];
  /** Assembly or Part */
  kind: Scalars['String']['output'];
  /** Material */
  material?: Maybe<Scalars['String']['output']>;
  /** Sku Name */
  name: Scalars['String']['output'];
  /** SKU number */
  sku: Scalars['String']['output'];
  sources: Array<InventorySource>;
  /** Suppliers */
  suppliers: Array<Supplier>;
  /** Team Id */
  teamId: Scalars['Float']['output'];
  /** UPC code */
  upc?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  updatedBy?: Maybe<Scalars['Float']['output']>;
};

export type SkuList = {
  __typename?: 'SkuList';
  /** Next cursor */
  nextCursor?: Maybe<Scalars['Float']['output']>;
  skus: Array<Sku>;
};

export type Supplier = {
  __typename?: 'Supplier';
  /** Supplier Address */
  address?: Maybe<InventoryAddress>;
  /** Supplier Capabilities */
  capabilities: Array<Scalars['String']['output']>;
  contactMethods: Array<InventoryContactMethod>;
  contacts: Array<InventoryContact>;
  createdAt: Scalars['DateTimeISO']['output'];
  createdBy: Scalars['Float']['output'];
  /** Supplier Id */
  id: Scalars['Float']['output'];
  /** HQ of Factory */
  kind: Scalars['String']['output'];
  /** Supplier Name */
  name: Scalars['String']['output'];
  /** Parent Supplier Id */
  parentId?: Maybe<Scalars['Float']['output']>;
  sources: Array<InventorySource>;
  /** Team Id */
  teamId: Scalars['Float']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  updatedBy: Scalars['Float']['output'];
};

export type SupplierList = {
  __typename?: 'SupplierList';
  /** Next cursor */
  nextCursor?: Maybe<Scalars['Float']['output']>;
  suppliers: Array<Supplier>;
};

export type Team = {
  __typename?: 'Team';
  contacts: Array<ContactMethod>;
  createdAt: Scalars['DateTimeISO']['output'];
  createdBy: Scalars['Float']['output'];
  deleted: Scalars['Boolean']['output'];
  /** Team Description */
  description?: Maybe<Scalars['String']['output']>;
  /** Team ID */
  id: Scalars['Float']['output'];
  /** Brand or Agency */
  kind: Scalars['String']['output'];
  /** Team Name */
  name: Scalars['String']['output'];
  /** Avatar picture. */
  picture: Scalars['String']['output'];
  /** Unique Team Slug */
  slug: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  updatedBy: Scalars['Float']['output'];
  /** Team Users */
  userEdges: Array<UserEdge>;
  verified: Scalars['Boolean']['output'];
};

export type TeamEdge = {
  __typename?: 'TeamEdge';
  createdAt: Scalars['DateTimeISO']['output'];
  /** Team User Role. */
  roles: Array<Scalars['String']['output']>;
  /** Team */
  team: Team;
};

export type TeamJoinRequest = {
  __typename?: 'TeamJoinRequest';
  createdAt: Scalars['DateTimeISO']['output'];
  createdBy: Scalars['Float']['output'];
  /** Team Join Request ID */
  id: Scalars['Float']['output'];
  /** Status */
  status: Scalars['String']['output'];
  /** Team ID */
  teamId: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  updatedBy?: Maybe<Scalars['Float']['output']>;
};

export type TeamList = {
  __typename?: 'TeamList';
  /** Next cursor */
  nextCursor?: Maybe<Scalars['Float']['output']>;
  teams: Array<Team>;
};

export type UpdateTeamInput = {
  /** Team Description. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Team ID */
  id: Scalars['Float']['input'];
  /** Brand or Agency. */
  kind?: InputMaybe<Scalars['String']['input']>;
  /** Team Name. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Avatar picture. */
  picture?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserInput = {
  bio?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Float']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  verifiedEmail?: InputMaybe<Scalars['Boolean']['input']>;
};

export type User = {
  __typename?: 'User';
  bio: Scalars['String']['output'];
  contacts: Array<ContactMethod>;
  country: Country;
  createdAt: Scalars['DateTimeISO']['output'];
  createdBy: Scalars['Float']['output'];
  deleted: Scalars['Boolean']['output'];
  /** Unique User Email */
  email: Scalars['String']['output'];
  /** First Name */
  firstName: Scalars['String']['output'];
  /** User ID */
  id: Scalars['Float']['output'];
  /** Last Name */
  lastName: Scalars['String']['output'];
  /** Avatar picture. */
  picture: Scalars['String']['output'];
  role: Scalars['String']['output'];
  /** User Team Edges */
  teamEdges: Array<TeamEdge>;
  timezone: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  updatedBy: Scalars['Float']['output'];
  verifiedEmail: Scalars['Boolean']['output'];
};

export type UserEdge = {
  __typename?: 'UserEdge';
  createdAt: Scalars['DateTimeISO']['output'];
  /** Team User Role. */
  roles: Array<Scalars['String']['output']>;
  /** Team User. */
  user: User;
};

export type UserList = {
  __typename?: 'UserList';
  /** Next cursor */
  nextCursor?: Maybe<Scalars['Float']['output']>;
  users: Array<User>;
};

export type ChecklistFragment = { __typename: 'Checklist', id: number, name: string, items: Array<{ __typename?: 'ChecklistItem', name: string, description: string, passCriteria: string, aqlCode: string }> };

export type ChecklistListQueryVariables = Exact<{
  teamId: Scalars['Float']['input'];
}>;


export type ChecklistListQuery = { __typename?: 'Query', checklist_list?: { __typename: 'ChecklistList', checklists: Array<{ __typename: 'Checklist', id: number, name: string, items: Array<{ __typename?: 'ChecklistItem', name: string, description: string, passCriteria: string, aqlCode: string }> }> } | null };

export type CountryListQueryVariables = Exact<{ [key: string]: never; }>;


export type CountryListQuery = { __typename?: 'Query', country_list?: Array<{ __typename: 'Country', label: string, code: string, phone: string }> | null };

export type InspectionAddressFragment = { __typename: 'InspectionAddress', addressLine1?: string | null, addressLine2?: string | null, city?: string | null, state?: string | null, country?: string | null, postalCode?: string | null };

export type SamplingFragment = { __typename: 'Sampling', quantity: number, inspectinLevel: string, notes: string, sku?: { __typename?: 'Sku', sku: string, name: string, description?: string | null } | null, criticalDefects: { __typename?: 'AQL', aql: number, sampleSize: number, acceptPoint: number, rejectPoint: number }, majorDefects: { __typename?: 'AQL', aql: number, sampleSize: number, acceptPoint: number, rejectPoint: number }, minorDefects: { __typename?: 'AQL', aql: number, sampleSize: number, acceptPoint: number, rejectPoint: number } };

export type InspectionChecklistFragment = { __typename: 'Checklist', id: number, name: string, items: Array<{ __typename: 'ChecklistItem', aqlCode: string, name: string, description: string, passCriteria: string }> };

export type InspectionBidCardFragment = { __typename: 'InspectionBid', id: number, ref: string, serviceType: string, currency?: string | null, notes?: string | null, createdAt: any, createdBy: number, brand: { __typename: 'Team', id: number, slug: string, name: string, description?: string | null, kind: string, picture: string, verified: boolean, contacts: Array<{ __typename: 'ContactMethod', type: string, value: string }> }, supplier?: { __typename: 'Supplier', kind: string, name: string, contactMethods: Array<{ __typename: 'InventoryContactMethod', key: string, value: string }> } | null, serviceDate?: { __typename?: 'ServiceDateRange', from: any, to?: any | null } | null, manDays?: { __typename?: 'ManDaysRange', from: number, to?: number | null } | null, rateRange?: { __typename?: 'RateRange', from: number, to?: number | null } | null, address?: { __typename: 'InspectionAddress', addressLine1?: string | null, addressLine2?: string | null, city?: string | null, state?: string | null, country?: string | null, postalCode?: string | null } | null, samplings: Array<{ __typename: 'Sampling', quantity: number, inspectinLevel: string, notes: string, sku?: { __typename?: 'Sku', sku: string, name: string, description?: string | null } | null, criticalDefects: { __typename?: 'AQL', aql: number, sampleSize: number, acceptPoint: number, rejectPoint: number }, majorDefects: { __typename?: 'AQL', aql: number, sampleSize: number, acceptPoint: number, rejectPoint: number }, minorDefects: { __typename?: 'AQL', aql: number, sampleSize: number, acceptPoint: number, rejectPoint: number } }>, checklist?: { __typename: 'Checklist', id: number, name: string, items: Array<{ __typename: 'ChecklistItem', aqlCode: string, name: string, description: string, passCriteria: string }> } | null };

export type InspectionBidListQueryVariables = Exact<{
  teamId: Scalars['Float']['input'];
}>;


export type InspectionBidListQuery = { __typename?: 'Query', inspection_bid_list?: { __typename: 'InspectionBidList', bids: Array<{ __typename: 'InspectionBid', id: number, ref: string, serviceType: string, currency?: string | null, notes?: string | null, createdAt: any, createdBy: number, brand: { __typename: 'Team', id: number, slug: string, name: string, description?: string | null, kind: string, picture: string, verified: boolean, contacts: Array<{ __typename: 'ContactMethod', type: string, value: string }> }, supplier?: { __typename: 'Supplier', kind: string, name: string, contactMethods: Array<{ __typename: 'InventoryContactMethod', key: string, value: string }> } | null, serviceDate?: { __typename?: 'ServiceDateRange', from: any, to?: any | null } | null, manDays?: { __typename?: 'ManDaysRange', from: number, to?: number | null } | null, rateRange?: { __typename?: 'RateRange', from: number, to?: number | null } | null, address?: { __typename: 'InspectionAddress', addressLine1?: string | null, addressLine2?: string | null, city?: string | null, state?: string | null, country?: string | null, postalCode?: string | null } | null, samplings: Array<{ __typename: 'Sampling', quantity: number, inspectinLevel: string, notes: string, sku?: { __typename?: 'Sku', sku: string, name: string, description?: string | null } | null, criticalDefects: { __typename?: 'AQL', aql: number, sampleSize: number, acceptPoint: number, rejectPoint: number }, majorDefects: { __typename?: 'AQL', aql: number, sampleSize: number, acceptPoint: number, rejectPoint: number }, minorDefects: { __typename?: 'AQL', aql: number, sampleSize: number, acceptPoint: number, rejectPoint: number } }>, checklist?: { __typename: 'Checklist', id: number, name: string, items: Array<{ __typename: 'ChecklistItem', aqlCode: string, name: string, description: string, passCriteria: string }> } | null }> } | null };

export type InspectionBidCreateMutationVariables = Exact<{
  bid: CreateInspectionBidInput;
}>;


export type InspectionBidCreateMutation = { __typename?: 'Mutation', inspection_bid_create: { __typename: 'InspectionBid', id: number, ref: string, serviceType: string, currency?: string | null, notes?: string | null, createdAt: any, createdBy: number, brand: { __typename: 'Team', id: number, slug: string, name: string, description?: string | null, kind: string, picture: string, verified: boolean, contacts: Array<{ __typename: 'ContactMethod', type: string, value: string }> }, supplier?: { __typename: 'Supplier', kind: string, name: string, contactMethods: Array<{ __typename: 'InventoryContactMethod', key: string, value: string }> } | null, serviceDate?: { __typename?: 'ServiceDateRange', from: any, to?: any | null } | null, manDays?: { __typename?: 'ManDaysRange', from: number, to?: number | null } | null, rateRange?: { __typename?: 'RateRange', from: number, to?: number | null } | null, address?: { __typename: 'InspectionAddress', addressLine1?: string | null, addressLine2?: string | null, city?: string | null, state?: string | null, country?: string | null, postalCode?: string | null } | null, samplings: Array<{ __typename: 'Sampling', quantity: number, inspectinLevel: string, notes: string, sku?: { __typename?: 'Sku', sku: string, name: string, description?: string | null } | null, criticalDefects: { __typename?: 'AQL', aql: number, sampleSize: number, acceptPoint: number, rejectPoint: number }, majorDefects: { __typename?: 'AQL', aql: number, sampleSize: number, acceptPoint: number, rejectPoint: number }, minorDefects: { __typename?: 'AQL', aql: number, sampleSize: number, acceptPoint: number, rejectPoint: number } }>, checklist?: { __typename: 'Checklist', id: number, name: string, items: Array<{ __typename: 'ChecklistItem', aqlCode: string, name: string, description: string, passCriteria: string }> } | null } };

export type AnvylConfigFragment = { __typename: 'Anvyl', id: number, teamId: number, anvylTeamId: number, anvylApiKey: string, enabled: boolean, createdAt: any, createdBy: number, updatedAt: any, updatedBy: number };

export type AnvylConfigQueryVariables = Exact<{
  teamId: Scalars['Float']['input'];
}>;


export type AnvylConfigQuery = { __typename?: 'Query', anvyl_config?: { __typename: 'Anvyl', id: number, teamId: number, anvylTeamId: number, anvylApiKey: string, enabled: boolean, createdAt: any, createdBy: number, updatedAt: any, updatedBy: number } | null };

export type AnvylConfigUpdateMutationVariables = Exact<{
  teamId: Scalars['Float']['input'];
  anvylTeamId: Scalars['Float']['input'];
  anvylApiKey: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
}>;


export type AnvylConfigUpdateMutation = { __typename?: 'Mutation', anvyl_config_update: { __typename: 'Anvyl', id: number, teamId: number, anvylTeamId: number, anvylApiKey: string, enabled: boolean, createdAt: any, createdBy: number, updatedAt: any, updatedBy: number } };

export type SkuFragment = { __typename: 'Sku', id: number, teamId: number, kind: string, name: string, description?: string | null, category?: string | null, material?: string | null, sku: string, upc?: string | null, asin?: string | null, hs?: string | null, hts?: string | null, createdAt: any, createdBy: number, updatedAt?: any | null, updatedBy?: number | null, sources: Array<{ __typename: 'InventorySource', key: string, value: string }> };

export type SkuListQueryVariables = Exact<{
  teamId: Scalars['Float']['input'];
}>;


export type SkuListQuery = { __typename?: 'Query', sku_list?: { __typename: 'SkuList', skus: Array<{ __typename: 'Sku', id: number, teamId: number, kind: string, name: string, description?: string | null, category?: string | null, material?: string | null, sku: string, upc?: string | null, asin?: string | null, hs?: string | null, hts?: string | null, createdAt: any, createdBy: number, updatedAt?: any | null, updatedBy?: number | null, sources: Array<{ __typename: 'InventorySource', key: string, value: string }> }> } | null };

export type SupplierFragment = { __typename: 'Supplier', id: number, teamId: number, parentId?: number | null, kind: string, name: string, capabilities: Array<string>, createdAt: any, createdBy: number, updatedAt: any, updatedBy: number, address?: { __typename: 'InventoryAddress', address_line1?: string | null, address_line2?: string | null, city?: string | null, state?: string | null, postal_code?: string | null, country?: string | null } | null, contactMethods: Array<{ __typename: 'InventoryContactMethod', key: string, value: string }>, contacts: Array<{ __typename: 'InventoryContact', id: number, name: string, address?: { __typename: 'InventoryAddress', address_line1?: string | null, address_line2?: string | null, city?: string | null, state?: string | null, postal_code?: string | null, country?: string | null } | null, contactMethods: Array<{ __typename: 'InventoryContactMethod', key: string, value: string }>, sources: Array<{ __typename: 'InventorySource', key: string, value: string }> }>, sources: Array<{ __typename: 'InventorySource', key: string, value: string }> };

export type SupplierListQueryVariables = Exact<{
  teamId: Scalars['Float']['input'];
}>;


export type SupplierListQuery = { __typename?: 'Query', supplier_list?: { __typename: 'SupplierList', suppliers: Array<{ __typename: 'Supplier', id: number, teamId: number, parentId?: number | null, kind: string, name: string, capabilities: Array<string>, createdAt: any, createdBy: number, updatedAt: any, updatedBy: number, address?: { __typename: 'InventoryAddress', address_line1?: string | null, address_line2?: string | null, city?: string | null, state?: string | null, postal_code?: string | null, country?: string | null } | null, contactMethods: Array<{ __typename: 'InventoryContactMethod', key: string, value: string }>, contacts: Array<{ __typename: 'InventoryContact', id: number, name: string, address?: { __typename: 'InventoryAddress', address_line1?: string | null, address_line2?: string | null, city?: string | null, state?: string | null, postal_code?: string | null, country?: string | null } | null, contactMethods: Array<{ __typename: 'InventoryContactMethod', key: string, value: string }>, sources: Array<{ __typename: 'InventorySource', key: string, value: string }> }>, sources: Array<{ __typename: 'InventorySource', key: string, value: string }> }> } | null };

export type TeamCreateMutationVariables = Exact<{
  slug: Scalars['String']['input'];
  name: Scalars['String']['input'];
  kind: Scalars['String']['input'];
}>;


export type TeamCreateMutation = { __typename?: 'Mutation', team_create: { __typename: 'Team', id: number, slug: string, name: string, description?: string | null, kind: string, picture: string, verified: boolean, createdAt: any, createdBy: number, contacts: Array<{ __typename: 'ContactMethod', type: string, value: string }> } };

export type TeamUpdateMutationVariables = Exact<{
  id: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
}>;


export type TeamUpdateMutation = { __typename?: 'Mutation', team_update: { __typename: 'Team', id: number, slug: string, name: string, description?: string | null, kind: string, picture: string, verified: boolean, createdAt: any, createdBy: number, contacts: Array<{ __typename: 'ContactMethod', type: string, value: string }> } };

export type TeamInfoFragment = { __typename: 'Team', id: number, verified: boolean, slug: string, name: string, description?: string | null, kind: string, picture: string, deleted: boolean, createdAt: any, updatedAt: any, contacts: Array<{ __typename: 'ContactMethod', type: string, value: string }>, userEdges: Array<{ __typename: 'UserEdge', createdAt: any, roles: Array<string>, user: { __typename: 'User', id: number, firstName: string, lastName: string, email: string, verifiedEmail: boolean, bio: string, picture: string, timezone: string, contacts: Array<{ __typename?: 'ContactMethod', type: string, value: string }>, country: { __typename: 'Country', code: string, label: string, phone: string } } }> };

export type TeamSearchFragment = { __typename: 'Team', id: number, verified: boolean, slug: string, name: string, description?: string | null, kind: string, picture: string, createdAt: any, updatedAt: any };

export type GetTeamByIdQueryVariables = Exact<{
  id: Scalars['Float']['input'];
}>;


export type GetTeamByIdQuery = { __typename?: 'Query', team_by_id?: { __typename: 'Team', id: number, verified: boolean, slug: string, name: string, description?: string | null, kind: string, picture: string, deleted: boolean, createdAt: any, updatedAt: any, contacts: Array<{ __typename: 'ContactMethod', type: string, value: string }>, userEdges: Array<{ __typename: 'UserEdge', createdAt: any, roles: Array<string>, user: { __typename: 'User', id: number, firstName: string, lastName: string, email: string, verifiedEmail: boolean, bio: string, picture: string, timezone: string, contacts: Array<{ __typename?: 'ContactMethod', type: string, value: string }>, country: { __typename: 'Country', code: string, label: string, phone: string } } }> } | null };

export type TeamListQueryVariables = Exact<{ [key: string]: never; }>;


export type TeamListQuery = { __typename?: 'Query', team_list?: { __typename: 'TeamList', nextCursor?: number | null, teams: Array<{ __typename: 'Team', id: number, verified: boolean, slug: string, name: string, description?: string | null, kind: string, picture: string, createdAt: any, updatedAt: any }> } | null };

export type TeamInfoQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type TeamInfoQuery = { __typename?: 'Query', team_by_slug?: { __typename: 'Team', id: number, verified: boolean, slug: string, name: string, description?: string | null, kind: string, picture: string, deleted: boolean, createdAt: any, updatedAt: any, contacts: Array<{ __typename: 'ContactMethod', type: string, value: string }>, userEdges: Array<{ __typename: 'UserEdge', createdAt: any, roles: Array<string>, user: { __typename: 'User', id: number, firstName: string, lastName: string, email: string, verifiedEmail: boolean, bio: string, picture: string, timezone: string, contacts: Array<{ __typename?: 'ContactMethod', type: string, value: string }>, country: { __typename: 'Country', code: string, label: string, phone: string } } }> } | null };

export type TeamAssignUserMutationVariables = Exact<{
  roles: Array<Scalars['String']['input']> | Scalars['String']['input'];
  userId: Scalars['Float']['input'];
  teamId: Scalars['Float']['input'];
}>;


export type TeamAssignUserMutation = { __typename?: 'Mutation', team_assign_user?: { __typename: 'Team', id: number, slug: string, name: string, description?: string | null, picture: string, verified: boolean, createdAt: any, createdBy: number, contacts: Array<{ __typename: 'ContactMethod', type: string, value: string }> } | null };

export type TeamJoinMutationVariables = Exact<{
  teamId: Scalars['Float']['input'];
}>;


export type TeamJoinMutation = { __typename?: 'Mutation', team_join?: { __typename?: 'TeamJoinRequest', status: string } | null };

export type UserMeQueryVariables = Exact<{ [key: string]: never; }>;


export type UserMeQuery = { __typename?: 'Query', user_me: { __typename: 'User', id: number, firstName: string, lastName: string, email: string, verifiedEmail: boolean, bio: string, picture: string, timezone: string, role: string, contacts: Array<{ __typename?: 'ContactMethod', type: string, value: string }>, country: { __typename: 'Country', code: string, label: string, phone: string }, teamEdges: Array<{ __typename: 'TeamEdge', createdAt: any, roles: Array<string>, team: { __typename: 'Team', id: number, slug: string, name: string, kind: string, picture: string, verified: boolean, description?: string | null, deleted: boolean, createdAt: any, createdBy: number, updatedAt: any, updatedBy: number, contacts: Array<{ __typename?: 'ContactMethod', type: string, value: string }> } }> } };

export type TeamUsersQueryVariables = Exact<{
  teamId: Scalars['Float']['input'];
}>;


export type TeamUsersQuery = { __typename?: 'Query', team_by_id?: { __typename: 'Team', id: number, slug: string, name: string, verified: boolean, description?: string | null, deleted: boolean, createdAt: any, createdBy: number, updatedAt: any, updatedBy: number, contacts: Array<{ __typename?: 'ContactMethod', type: string, value: string }>, userEdges: Array<{ __typename: 'UserEdge', roles: Array<string>, user: { __typename: 'User', id: number, firstName: string, lastName: string, email: string, verifiedEmail: boolean, bio: string, picture: string, timezone: string, role: string, contacts: Array<{ __typename?: 'ContactMethod', type: string, value: string }>, country: { __typename: 'Country', code: string, label: string, phone: string } } }> } | null };

export type UserUpdateMutationVariables = Exact<{
  id: Scalars['Float']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  verifiedEmail?: InputMaybe<Scalars['Boolean']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
}>;


export type UserUpdateMutation = { __typename?: 'Mutation', user_update: { __typename: 'User', id: number } };

export type UserListQueryVariables = Exact<{ [key: string]: never; }>;


export type UserListQuery = { __typename?: 'Query', user_list?: { __typename: 'UserList', nextCursor?: number | null, users: Array<{ __typename: 'User', id: number, firstName: string, lastName: string, email: string, picture: string, teamEdges: Array<{ __typename?: 'TeamEdge', roles: Array<string>, team: { __typename?: 'Team', id: number, name: string } }> }> } | null };

export type UserByIdQueryVariables = Exact<{
  id: Scalars['Float']['input'];
}>;


export type UserByIdQuery = { __typename?: 'Query', user_by_id?: { __typename: 'User', id: number, firstName: string, lastName: string, email: string, picture: string } | null };

export const ChecklistFragmentDoc = gql`
    fragment Checklist on Checklist {
  __typename
  id
  name
  items {
    name
    description
    passCriteria
    aqlCode
  }
}
    `;
export const InspectionAddressFragmentDoc = gql`
    fragment InspectionAddress on InspectionAddress {
  __typename
  addressLine1
  addressLine2
  city
  state
  country
  postalCode
}
    `;
export const SamplingFragmentDoc = gql`
    fragment Sampling on Sampling {
  __typename
  sku {
    sku
    name
    description
  }
  quantity
  inspectinLevel
  criticalDefects {
    aql
    sampleSize
    acceptPoint
    rejectPoint
  }
  majorDefects {
    aql
    sampleSize
    acceptPoint
    rejectPoint
  }
  minorDefects {
    aql
    sampleSize
    acceptPoint
    rejectPoint
  }
  notes
}
    `;
export const InspectionChecklistFragmentDoc = gql`
    fragment InspectionChecklist on Checklist {
  __typename
  id
  name
  items {
    __typename
    aqlCode
    name
    description
    passCriteria
  }
}
    `;
export const InspectionBidCardFragmentDoc = gql`
    fragment InspectionBidCard on InspectionBid {
  __typename
  id
  ref
  brand {
    __typename
    id
    slug
    name
    description
    kind
    picture
    contacts {
      __typename
      type
      value
    }
    verified
  }
  serviceType
  supplier {
    __typename
    kind
    name
    contactMethods {
      __typename
      key
      value
    }
  }
  serviceDate {
    from
    to
  }
  manDays {
    from
    to
  }
  rateRange {
    from
    to
  }
  currency
  address {
    ...InspectionAddress
  }
  samplings {
    ...Sampling
  }
  checklist {
    ...InspectionChecklist
  }
  notes
  createdAt
  createdBy
}
    ${InspectionAddressFragmentDoc}
${SamplingFragmentDoc}
${InspectionChecklistFragmentDoc}`;
export const AnvylConfigFragmentDoc = gql`
    fragment AnvylConfig on Anvyl {
  __typename
  id
  teamId
  anvylTeamId
  anvylApiKey
  enabled
  createdAt
  createdBy
  updatedAt
  updatedBy
}
    `;
export const SkuFragmentDoc = gql`
    fragment Sku on Sku {
  __typename
  id
  teamId
  kind
  name
  description
  category
  material
  sku
  upc
  asin
  hs
  hts
  sources {
    __typename
    key
    value
  }
  createdAt
  createdBy
  updatedAt
  updatedBy
}
    `;
export const SupplierFragmentDoc = gql`
    fragment Supplier on Supplier {
  __typename
  id
  teamId
  parentId
  kind
  name
  capabilities
  address {
    __typename
    address_line1
    address_line2
    city
    state
    postal_code
    country
  }
  contactMethods {
    __typename
    key
    value
  }
  contacts {
    __typename
    id
    name
    address {
      __typename
      address_line1
      address_line2
      city
      state
      postal_code
      country
    }
    contactMethods {
      __typename
      key
      value
    }
    sources {
      __typename
      key
      value
    }
  }
  sources {
    __typename
    key
    value
  }
  createdAt
  createdBy
  updatedAt
  updatedBy
}
    `;
export const TeamInfoFragmentDoc = gql`
    fragment TeamInfo on Team {
  __typename
  id
  verified
  slug
  name
  description
  kind
  picture
  contacts {
    __typename
    type
    value
  }
  deleted
  createdAt
  updatedAt
  userEdges {
    __typename
    createdAt
    roles
    user {
      __typename
      id
      firstName
      lastName
      email
      verifiedEmail
      bio
      picture
      timezone
      contacts {
        type
        value
      }
      country {
        __typename
        code
        label
        phone
      }
    }
  }
}
    `;
export const TeamSearchFragmentDoc = gql`
    fragment TeamSearch on Team {
  __typename
  id
  verified
  slug
  name
  description
  kind
  picture
  createdAt
  updatedAt
}
    `;
export const ChecklistListDocument = gql`
    query checklistList($teamId: Float!) {
  checklist_list(teamId: $teamId) {
    __typename
    checklists {
      ...Checklist
    }
  }
}
    ${ChecklistFragmentDoc}`;

/**
 * __useChecklistListQuery__
 *
 * To run a query within a React component, call `useChecklistListQuery` and pass it any options that fit your needs.
 * When your component renders, `useChecklistListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChecklistListQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useChecklistListQuery(baseOptions: Apollo.QueryHookOptions<ChecklistListQuery, ChecklistListQueryVariables> & ({ variables: ChecklistListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChecklistListQuery, ChecklistListQueryVariables>(ChecklistListDocument, options);
      }
export function useChecklistListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChecklistListQuery, ChecklistListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChecklistListQuery, ChecklistListQueryVariables>(ChecklistListDocument, options);
        }
export function useChecklistListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ChecklistListQuery, ChecklistListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ChecklistListQuery, ChecklistListQueryVariables>(ChecklistListDocument, options);
        }
export type ChecklistListQueryHookResult = ReturnType<typeof useChecklistListQuery>;
export type ChecklistListLazyQueryHookResult = ReturnType<typeof useChecklistListLazyQuery>;
export type ChecklistListSuspenseQueryHookResult = ReturnType<typeof useChecklistListSuspenseQuery>;
export type ChecklistListQueryResult = Apollo.QueryResult<ChecklistListQuery, ChecklistListQueryVariables>;
export const CountryListDocument = gql`
    query countryList {
  country_list {
    __typename
    label
    code
    phone
  }
}
    `;

/**
 * __useCountryListQuery__
 *
 * To run a query within a React component, call `useCountryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountryListQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountryListQuery(baseOptions?: Apollo.QueryHookOptions<CountryListQuery, CountryListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountryListQuery, CountryListQueryVariables>(CountryListDocument, options);
      }
export function useCountryListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountryListQuery, CountryListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountryListQuery, CountryListQueryVariables>(CountryListDocument, options);
        }
export function useCountryListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CountryListQuery, CountryListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CountryListQuery, CountryListQueryVariables>(CountryListDocument, options);
        }
export type CountryListQueryHookResult = ReturnType<typeof useCountryListQuery>;
export type CountryListLazyQueryHookResult = ReturnType<typeof useCountryListLazyQuery>;
export type CountryListSuspenseQueryHookResult = ReturnType<typeof useCountryListSuspenseQuery>;
export type CountryListQueryResult = Apollo.QueryResult<CountryListQuery, CountryListQueryVariables>;
export const InspectionBidListDocument = gql`
    query inspectionBidList($teamId: Float!) {
  inspection_bid_list(teamId: $teamId) {
    __typename
    bids {
      ...InspectionBidCard
    }
  }
}
    ${InspectionBidCardFragmentDoc}`;

/**
 * __useInspectionBidListQuery__
 *
 * To run a query within a React component, call `useInspectionBidListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInspectionBidListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInspectionBidListQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useInspectionBidListQuery(baseOptions: Apollo.QueryHookOptions<InspectionBidListQuery, InspectionBidListQueryVariables> & ({ variables: InspectionBidListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InspectionBidListQuery, InspectionBidListQueryVariables>(InspectionBidListDocument, options);
      }
export function useInspectionBidListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InspectionBidListQuery, InspectionBidListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InspectionBidListQuery, InspectionBidListQueryVariables>(InspectionBidListDocument, options);
        }
export function useInspectionBidListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<InspectionBidListQuery, InspectionBidListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InspectionBidListQuery, InspectionBidListQueryVariables>(InspectionBidListDocument, options);
        }
export type InspectionBidListQueryHookResult = ReturnType<typeof useInspectionBidListQuery>;
export type InspectionBidListLazyQueryHookResult = ReturnType<typeof useInspectionBidListLazyQuery>;
export type InspectionBidListSuspenseQueryHookResult = ReturnType<typeof useInspectionBidListSuspenseQuery>;
export type InspectionBidListQueryResult = Apollo.QueryResult<InspectionBidListQuery, InspectionBidListQueryVariables>;
export const InspectionBidCreateDocument = gql`
    mutation inspectionBidCreate($bid: CreateInspectionBidInput!) {
  inspection_bid_create(bid: $bid) {
    ...InspectionBidCard
  }
}
    ${InspectionBidCardFragmentDoc}`;
export type InspectionBidCreateMutationFn = Apollo.MutationFunction<InspectionBidCreateMutation, InspectionBidCreateMutationVariables>;

/**
 * __useInspectionBidCreateMutation__
 *
 * To run a mutation, you first call `useInspectionBidCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInspectionBidCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inspectionBidCreateMutation, { data, loading, error }] = useInspectionBidCreateMutation({
 *   variables: {
 *      bid: // value for 'bid'
 *   },
 * });
 */
export function useInspectionBidCreateMutation(baseOptions?: Apollo.MutationHookOptions<InspectionBidCreateMutation, InspectionBidCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InspectionBidCreateMutation, InspectionBidCreateMutationVariables>(InspectionBidCreateDocument, options);
      }
export type InspectionBidCreateMutationHookResult = ReturnType<typeof useInspectionBidCreateMutation>;
export type InspectionBidCreateMutationResult = Apollo.MutationResult<InspectionBidCreateMutation>;
export type InspectionBidCreateMutationOptions = Apollo.BaseMutationOptions<InspectionBidCreateMutation, InspectionBidCreateMutationVariables>;
export const AnvylConfigDocument = gql`
    query anvylConfig($teamId: Float!) {
  anvyl_config(teamId: $teamId) {
    ...AnvylConfig
  }
}
    ${AnvylConfigFragmentDoc}`;

/**
 * __useAnvylConfigQuery__
 *
 * To run a query within a React component, call `useAnvylConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnvylConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnvylConfigQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useAnvylConfigQuery(baseOptions: Apollo.QueryHookOptions<AnvylConfigQuery, AnvylConfigQueryVariables> & ({ variables: AnvylConfigQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnvylConfigQuery, AnvylConfigQueryVariables>(AnvylConfigDocument, options);
      }
export function useAnvylConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnvylConfigQuery, AnvylConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnvylConfigQuery, AnvylConfigQueryVariables>(AnvylConfigDocument, options);
        }
export function useAnvylConfigSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AnvylConfigQuery, AnvylConfigQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AnvylConfigQuery, AnvylConfigQueryVariables>(AnvylConfigDocument, options);
        }
export type AnvylConfigQueryHookResult = ReturnType<typeof useAnvylConfigQuery>;
export type AnvylConfigLazyQueryHookResult = ReturnType<typeof useAnvylConfigLazyQuery>;
export type AnvylConfigSuspenseQueryHookResult = ReturnType<typeof useAnvylConfigSuspenseQuery>;
export type AnvylConfigQueryResult = Apollo.QueryResult<AnvylConfigQuery, AnvylConfigQueryVariables>;
export const AnvylConfigUpdateDocument = gql`
    mutation anvylConfigUpdate($teamId: Float!, $anvylTeamId: Float!, $anvylApiKey: String!, $enabled: Boolean!) {
  anvyl_config_update(
    anvyl: {teamId: $teamId, anvylTeamId: $anvylTeamId, anvylApiKey: $anvylApiKey, enabled: $enabled}
  ) {
    ...AnvylConfig
  }
}
    ${AnvylConfigFragmentDoc}`;
export type AnvylConfigUpdateMutationFn = Apollo.MutationFunction<AnvylConfigUpdateMutation, AnvylConfigUpdateMutationVariables>;

/**
 * __useAnvylConfigUpdateMutation__
 *
 * To run a mutation, you first call `useAnvylConfigUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnvylConfigUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [anvylConfigUpdateMutation, { data, loading, error }] = useAnvylConfigUpdateMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      anvylTeamId: // value for 'anvylTeamId'
 *      anvylApiKey: // value for 'anvylApiKey'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useAnvylConfigUpdateMutation(baseOptions?: Apollo.MutationHookOptions<AnvylConfigUpdateMutation, AnvylConfigUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AnvylConfigUpdateMutation, AnvylConfigUpdateMutationVariables>(AnvylConfigUpdateDocument, options);
      }
export type AnvylConfigUpdateMutationHookResult = ReturnType<typeof useAnvylConfigUpdateMutation>;
export type AnvylConfigUpdateMutationResult = Apollo.MutationResult<AnvylConfigUpdateMutation>;
export type AnvylConfigUpdateMutationOptions = Apollo.BaseMutationOptions<AnvylConfigUpdateMutation, AnvylConfigUpdateMutationVariables>;
export const SkuListDocument = gql`
    query skuList($teamId: Float!) {
  sku_list(teamId: $teamId) {
    __typename
    skus {
      ...Sku
    }
  }
}
    ${SkuFragmentDoc}`;

/**
 * __useSkuListQuery__
 *
 * To run a query within a React component, call `useSkuListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkuListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkuListQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useSkuListQuery(baseOptions: Apollo.QueryHookOptions<SkuListQuery, SkuListQueryVariables> & ({ variables: SkuListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SkuListQuery, SkuListQueryVariables>(SkuListDocument, options);
      }
export function useSkuListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SkuListQuery, SkuListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SkuListQuery, SkuListQueryVariables>(SkuListDocument, options);
        }
export function useSkuListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SkuListQuery, SkuListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SkuListQuery, SkuListQueryVariables>(SkuListDocument, options);
        }
export type SkuListQueryHookResult = ReturnType<typeof useSkuListQuery>;
export type SkuListLazyQueryHookResult = ReturnType<typeof useSkuListLazyQuery>;
export type SkuListSuspenseQueryHookResult = ReturnType<typeof useSkuListSuspenseQuery>;
export type SkuListQueryResult = Apollo.QueryResult<SkuListQuery, SkuListQueryVariables>;
export const SupplierListDocument = gql`
    query supplierList($teamId: Float!) {
  supplier_list(teamId: $teamId) {
    __typename
    suppliers {
      ...Supplier
    }
  }
}
    ${SupplierFragmentDoc}`;

/**
 * __useSupplierListQuery__
 *
 * To run a query within a React component, call `useSupplierListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierListQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useSupplierListQuery(baseOptions: Apollo.QueryHookOptions<SupplierListQuery, SupplierListQueryVariables> & ({ variables: SupplierListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SupplierListQuery, SupplierListQueryVariables>(SupplierListDocument, options);
      }
export function useSupplierListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupplierListQuery, SupplierListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SupplierListQuery, SupplierListQueryVariables>(SupplierListDocument, options);
        }
export function useSupplierListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SupplierListQuery, SupplierListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SupplierListQuery, SupplierListQueryVariables>(SupplierListDocument, options);
        }
export type SupplierListQueryHookResult = ReturnType<typeof useSupplierListQuery>;
export type SupplierListLazyQueryHookResult = ReturnType<typeof useSupplierListLazyQuery>;
export type SupplierListSuspenseQueryHookResult = ReturnType<typeof useSupplierListSuspenseQuery>;
export type SupplierListQueryResult = Apollo.QueryResult<SupplierListQuery, SupplierListQueryVariables>;
export const TeamCreateDocument = gql`
    mutation teamCreate($slug: String!, $name: String!, $kind: String!) {
  team_create(team: {slug: $slug, name: $name, kind: $kind}) {
    __typename
    id
    slug
    name
    description
    kind
    picture
    contacts {
      __typename
      type
      value
    }
    verified
    createdAt
    createdBy
  }
}
    `;
export type TeamCreateMutationFn = Apollo.MutationFunction<TeamCreateMutation, TeamCreateMutationVariables>;

/**
 * __useTeamCreateMutation__
 *
 * To run a mutation, you first call `useTeamCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamCreateMutation, { data, loading, error }] = useTeamCreateMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      name: // value for 'name'
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useTeamCreateMutation(baseOptions?: Apollo.MutationHookOptions<TeamCreateMutation, TeamCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TeamCreateMutation, TeamCreateMutationVariables>(TeamCreateDocument, options);
      }
export type TeamCreateMutationHookResult = ReturnType<typeof useTeamCreateMutation>;
export type TeamCreateMutationResult = Apollo.MutationResult<TeamCreateMutation>;
export type TeamCreateMutationOptions = Apollo.BaseMutationOptions<TeamCreateMutation, TeamCreateMutationVariables>;
export const TeamUpdateDocument = gql`
    mutation teamUpdate($id: Float!, $name: String, $description: String, $kind: String, $picture: String) {
  team_update(
    team: {id: $id, name: $name, description: $description, kind: $kind, picture: $picture}
  ) {
    __typename
    id
    slug
    name
    description
    kind
    picture
    contacts {
      __typename
      type
      value
    }
    verified
    createdAt
    createdBy
  }
}
    `;
export type TeamUpdateMutationFn = Apollo.MutationFunction<TeamUpdateMutation, TeamUpdateMutationVariables>;

/**
 * __useTeamUpdateMutation__
 *
 * To run a mutation, you first call `useTeamUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamUpdateMutation, { data, loading, error }] = useTeamUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      kind: // value for 'kind'
 *      picture: // value for 'picture'
 *   },
 * });
 */
export function useTeamUpdateMutation(baseOptions?: Apollo.MutationHookOptions<TeamUpdateMutation, TeamUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TeamUpdateMutation, TeamUpdateMutationVariables>(TeamUpdateDocument, options);
      }
export type TeamUpdateMutationHookResult = ReturnType<typeof useTeamUpdateMutation>;
export type TeamUpdateMutationResult = Apollo.MutationResult<TeamUpdateMutation>;
export type TeamUpdateMutationOptions = Apollo.BaseMutationOptions<TeamUpdateMutation, TeamUpdateMutationVariables>;
export const GetTeamByIdDocument = gql`
    query getTeamById($id: Float!) {
  team_by_id(id: $id) {
    ...TeamInfo
  }
}
    ${TeamInfoFragmentDoc}`;

/**
 * __useGetTeamByIdQuery__
 *
 * To run a query within a React component, call `useGetTeamByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTeamByIdQuery(baseOptions: Apollo.QueryHookOptions<GetTeamByIdQuery, GetTeamByIdQueryVariables> & ({ variables: GetTeamByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamByIdQuery, GetTeamByIdQueryVariables>(GetTeamByIdDocument, options);
      }
export function useGetTeamByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamByIdQuery, GetTeamByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamByIdQuery, GetTeamByIdQueryVariables>(GetTeamByIdDocument, options);
        }
export function useGetTeamByIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTeamByIdQuery, GetTeamByIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTeamByIdQuery, GetTeamByIdQueryVariables>(GetTeamByIdDocument, options);
        }
export type GetTeamByIdQueryHookResult = ReturnType<typeof useGetTeamByIdQuery>;
export type GetTeamByIdLazyQueryHookResult = ReturnType<typeof useGetTeamByIdLazyQuery>;
export type GetTeamByIdSuspenseQueryHookResult = ReturnType<typeof useGetTeamByIdSuspenseQuery>;
export type GetTeamByIdQueryResult = Apollo.QueryResult<GetTeamByIdQuery, GetTeamByIdQueryVariables>;
export const TeamListDocument = gql`
    query teamList {
  team_list {
    __typename
    nextCursor
    teams {
      ...TeamSearch
    }
  }
}
    ${TeamSearchFragmentDoc}`;

/**
 * __useTeamListQuery__
 *
 * To run a query within a React component, call `useTeamListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamListQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamListQuery(baseOptions?: Apollo.QueryHookOptions<TeamListQuery, TeamListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamListQuery, TeamListQueryVariables>(TeamListDocument, options);
      }
export function useTeamListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamListQuery, TeamListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamListQuery, TeamListQueryVariables>(TeamListDocument, options);
        }
export function useTeamListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TeamListQuery, TeamListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TeamListQuery, TeamListQueryVariables>(TeamListDocument, options);
        }
export type TeamListQueryHookResult = ReturnType<typeof useTeamListQuery>;
export type TeamListLazyQueryHookResult = ReturnType<typeof useTeamListLazyQuery>;
export type TeamListSuspenseQueryHookResult = ReturnType<typeof useTeamListSuspenseQuery>;
export type TeamListQueryResult = Apollo.QueryResult<TeamListQuery, TeamListQueryVariables>;
export const TeamInfoDocument = gql`
    query teamInfo($slug: String!) {
  team_by_slug(slug: $slug) {
    __typename
    id
    verified
    slug
    name
    description
    kind
    picture
    contacts {
      __typename
      type
      value
    }
    deleted
    createdAt
    updatedAt
    userEdges {
      __typename
      createdAt
      roles
      user {
        __typename
        id
        firstName
        lastName
        email
        verifiedEmail
        bio
        picture
        timezone
        contacts {
          type
          value
        }
        country {
          __typename
          code
          label
          phone
        }
      }
    }
  }
}
    `;

/**
 * __useTeamInfoQuery__
 *
 * To run a query within a React component, call `useTeamInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamInfoQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useTeamInfoQuery(baseOptions: Apollo.QueryHookOptions<TeamInfoQuery, TeamInfoQueryVariables> & ({ variables: TeamInfoQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamInfoQuery, TeamInfoQueryVariables>(TeamInfoDocument, options);
      }
export function useTeamInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamInfoQuery, TeamInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamInfoQuery, TeamInfoQueryVariables>(TeamInfoDocument, options);
        }
export function useTeamInfoSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TeamInfoQuery, TeamInfoQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TeamInfoQuery, TeamInfoQueryVariables>(TeamInfoDocument, options);
        }
export type TeamInfoQueryHookResult = ReturnType<typeof useTeamInfoQuery>;
export type TeamInfoLazyQueryHookResult = ReturnType<typeof useTeamInfoLazyQuery>;
export type TeamInfoSuspenseQueryHookResult = ReturnType<typeof useTeamInfoSuspenseQuery>;
export type TeamInfoQueryResult = Apollo.QueryResult<TeamInfoQuery, TeamInfoQueryVariables>;
export const TeamAssignUserDocument = gql`
    mutation teamAssignUser($roles: [String!]!, $userId: Float!, $teamId: Float!) {
  team_assign_user(roles: $roles, userId: $userId, teamId: $teamId) {
    __typename
    id
    slug
    name
    description
    picture
    contacts {
      __typename
      type
      value
    }
    verified
    createdAt
    createdBy
  }
}
    `;
export type TeamAssignUserMutationFn = Apollo.MutationFunction<TeamAssignUserMutation, TeamAssignUserMutationVariables>;

/**
 * __useTeamAssignUserMutation__
 *
 * To run a mutation, you first call `useTeamAssignUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamAssignUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamAssignUserMutation, { data, loading, error }] = useTeamAssignUserMutation({
 *   variables: {
 *      roles: // value for 'roles'
 *      userId: // value for 'userId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTeamAssignUserMutation(baseOptions?: Apollo.MutationHookOptions<TeamAssignUserMutation, TeamAssignUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TeamAssignUserMutation, TeamAssignUserMutationVariables>(TeamAssignUserDocument, options);
      }
export type TeamAssignUserMutationHookResult = ReturnType<typeof useTeamAssignUserMutation>;
export type TeamAssignUserMutationResult = Apollo.MutationResult<TeamAssignUserMutation>;
export type TeamAssignUserMutationOptions = Apollo.BaseMutationOptions<TeamAssignUserMutation, TeamAssignUserMutationVariables>;
export const TeamJoinDocument = gql`
    mutation teamJoin($teamId: Float!) {
  team_join(teamId: $teamId) {
    status
  }
}
    `;
export type TeamJoinMutationFn = Apollo.MutationFunction<TeamJoinMutation, TeamJoinMutationVariables>;

/**
 * __useTeamJoinMutation__
 *
 * To run a mutation, you first call `useTeamJoinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamJoinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamJoinMutation, { data, loading, error }] = useTeamJoinMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTeamJoinMutation(baseOptions?: Apollo.MutationHookOptions<TeamJoinMutation, TeamJoinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TeamJoinMutation, TeamJoinMutationVariables>(TeamJoinDocument, options);
      }
export type TeamJoinMutationHookResult = ReturnType<typeof useTeamJoinMutation>;
export type TeamJoinMutationResult = Apollo.MutationResult<TeamJoinMutation>;
export type TeamJoinMutationOptions = Apollo.BaseMutationOptions<TeamJoinMutation, TeamJoinMutationVariables>;
export const UserMeDocument = gql`
    query userMe {
  user_me {
    __typename
    id
    firstName
    lastName
    email
    verifiedEmail
    bio
    picture
    timezone
    contacts {
      type
      value
    }
    country {
      __typename
      code
      label
      phone
    }
    role
    teamEdges {
      __typename
      createdAt
      roles
      team {
        __typename
        id
        slug
        name
        kind
        picture
        verified
        description
        deleted
        createdAt
        createdBy
        updatedAt
        updatedBy
        contacts {
          type
          value
        }
      }
    }
  }
}
    `;

/**
 * __useUserMeQuery__
 *
 * To run a query within a React component, call `useUserMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserMeQuery(baseOptions?: Apollo.QueryHookOptions<UserMeQuery, UserMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserMeQuery, UserMeQueryVariables>(UserMeDocument, options);
      }
export function useUserMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserMeQuery, UserMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserMeQuery, UserMeQueryVariables>(UserMeDocument, options);
        }
export function useUserMeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserMeQuery, UserMeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserMeQuery, UserMeQueryVariables>(UserMeDocument, options);
        }
export type UserMeQueryHookResult = ReturnType<typeof useUserMeQuery>;
export type UserMeLazyQueryHookResult = ReturnType<typeof useUserMeLazyQuery>;
export type UserMeSuspenseQueryHookResult = ReturnType<typeof useUserMeSuspenseQuery>;
export type UserMeQueryResult = Apollo.QueryResult<UserMeQuery, UserMeQueryVariables>;
export const TeamUsersDocument = gql`
    query teamUsers($teamId: Float!) {
  team_by_id(id: $teamId) {
    __typename
    id
    slug
    name
    verified
    description
    deleted
    createdAt
    createdBy
    updatedAt
    updatedBy
    contacts {
      type
      value
    }
    userEdges {
      __typename
      roles
      user {
        __typename
        id
        firstName
        lastName
        email
        verifiedEmail
        bio
        picture
        timezone
        contacts {
          type
          value
        }
        country {
          __typename
          code
          label
          phone
        }
        role
      }
    }
  }
}
    `;

/**
 * __useTeamUsersQuery__
 *
 * To run a query within a React component, call `useTeamUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamUsersQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTeamUsersQuery(baseOptions: Apollo.QueryHookOptions<TeamUsersQuery, TeamUsersQueryVariables> & ({ variables: TeamUsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamUsersQuery, TeamUsersQueryVariables>(TeamUsersDocument, options);
      }
export function useTeamUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamUsersQuery, TeamUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamUsersQuery, TeamUsersQueryVariables>(TeamUsersDocument, options);
        }
export function useTeamUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TeamUsersQuery, TeamUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TeamUsersQuery, TeamUsersQueryVariables>(TeamUsersDocument, options);
        }
export type TeamUsersQueryHookResult = ReturnType<typeof useTeamUsersQuery>;
export type TeamUsersLazyQueryHookResult = ReturnType<typeof useTeamUsersLazyQuery>;
export type TeamUsersSuspenseQueryHookResult = ReturnType<typeof useTeamUsersSuspenseQuery>;
export type TeamUsersQueryResult = Apollo.QueryResult<TeamUsersQuery, TeamUsersQueryVariables>;
export const UserUpdateDocument = gql`
    mutation userUpdate($id: Float!, $firstName: String, $lastName: String, $verifiedEmail: Boolean, $bio: String, $picture: String, $timezone: String, $countryCode: String) {
  user_update(
    user: {id: $id, firstName: $firstName, lastName: $lastName, verifiedEmail: $verifiedEmail, bio: $bio, picture: $picture, timezone: $timezone, countryCode: $countryCode}
  ) {
    __typename
    id
  }
}
    `;
export type UserUpdateMutationFn = Apollo.MutationFunction<UserUpdateMutation, UserUpdateMutationVariables>;

/**
 * __useUserUpdateMutation__
 *
 * To run a mutation, you first call `useUserUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateMutation, { data, loading, error }] = useUserUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      verifiedEmail: // value for 'verifiedEmail'
 *      bio: // value for 'bio'
 *      picture: // value for 'picture'
 *      timezone: // value for 'timezone'
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useUserUpdateMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdateMutation, UserUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUpdateMutation, UserUpdateMutationVariables>(UserUpdateDocument, options);
      }
export type UserUpdateMutationHookResult = ReturnType<typeof useUserUpdateMutation>;
export type UserUpdateMutationResult = Apollo.MutationResult<UserUpdateMutation>;
export type UserUpdateMutationOptions = Apollo.BaseMutationOptions<UserUpdateMutation, UserUpdateMutationVariables>;
export const UserListDocument = gql`
    query userList {
  user_list(limit: 10) {
    __typename
    users {
      __typename
      id
      firstName
      lastName
      email
      picture
      teamEdges {
        roles
        team {
          id
          name
        }
      }
    }
    nextCursor
  }
}
    `;

/**
 * __useUserListQuery__
 *
 * To run a query within a React component, call `useUserListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserListQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserListQuery(baseOptions?: Apollo.QueryHookOptions<UserListQuery, UserListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserListQuery, UserListQueryVariables>(UserListDocument, options);
      }
export function useUserListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserListQuery, UserListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserListQuery, UserListQueryVariables>(UserListDocument, options);
        }
export function useUserListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserListQuery, UserListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserListQuery, UserListQueryVariables>(UserListDocument, options);
        }
export type UserListQueryHookResult = ReturnType<typeof useUserListQuery>;
export type UserListLazyQueryHookResult = ReturnType<typeof useUserListLazyQuery>;
export type UserListSuspenseQueryHookResult = ReturnType<typeof useUserListSuspenseQuery>;
export type UserListQueryResult = Apollo.QueryResult<UserListQuery, UserListQueryVariables>;
export const UserByIdDocument = gql`
    query userById($id: Float!) {
  user_by_id(id: $id) {
    __typename
    id
    firstName
    lastName
    email
    picture
  }
}
    `;

/**
 * __useUserByIdQuery__
 *
 * To run a query within a React component, call `useUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserByIdQuery(baseOptions: Apollo.QueryHookOptions<UserByIdQuery, UserByIdQueryVariables> & ({ variables: UserByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, options);
      }
export function useUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserByIdQuery, UserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, options);
        }
export function useUserByIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserByIdQuery, UserByIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, options);
        }
export type UserByIdQueryHookResult = ReturnType<typeof useUserByIdQuery>;
export type UserByIdLazyQueryHookResult = ReturnType<typeof useUserByIdLazyQuery>;
export type UserByIdSuspenseQueryHookResult = ReturnType<typeof useUserByIdSuspenseQuery>;
export type UserByIdQueryResult = Apollo.QueryResult<UserByIdQuery, UserByIdQueryVariables>;
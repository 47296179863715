import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import { useTranslation } from "../../../i18n";
import { useTeam } from "../TeamLayout";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Verified from "@mui/icons-material/Verified";
import { DateTime } from "../../../components/DateTime";
import { Button, Chip, Option, Select } from "@mui/joy";

const columns: GridColDef[] = [
  {
    field: "firstName",
    headerName: "First Name",
    width: 180,
    editable: false,
  },
  {
    field: "lastName",
    headerName: "Last Name",
    width: 180,
    editable: false,
  },
  {
    field: "email",
    headerName: "Email",
    width: 270,
    editable: false,
    renderCell: (params) => {
      if (params.row.verifiedEmail) {
        return (
          <span>
            <Verified
              color="success"
              fontSize="small"
              sx={{ mr: 1, verticalAlign: "sub" }}
            />
            {params.row.email}
          </span>
        );
      }
      return params.row.email;
    },
  },
  {
    field: "country",
    headerName: "Country",
    width: 180,
    editable: false,
    renderCell: ({ value: country }) => {
      return (
        <span>
          <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
            alt=""
          />
          {" " + country.code}
        </span>
      );
    },
  },
  {
    field: "createdAt",
    headerName: "Join Date",
    renderCell: ({ value }) => <DateTime value={new Date(value)} />,
    width: 180,
    editable: false,
  },
  {
    field: "roles",
    headerName: "Roles",
    width: 220,
    editable: true,
    valueOptions: ["OWNER", "ADMIN", "MANAGER"],
    renderCell: ({ value, id, field }) => {
      return (
        <div style={{ position: "relative" }}>
          {value.map((role: string) => (
            <Chip key={role} sx={{ mr: 1 }}>
              {role}
            </Chip>
          ))}
          {/* {(interactions as any)["1"] &&
            (interactions as any)["1"] === `edit-start:${field}:${id}` && (
              <Avatar
                style={{ position: "absolute", top: 2, right: 0 }}
                variant="solid"
                alt="Remy Sharp"
                src="/static/images/avatar/1.jpg"
                size="sm"
              />
            )} */}
        </div>
      );
    },
    renderEditCell: (params) => {
      return (
        <Select
          multiple
          defaultValue={params.value}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", gap: "0.25rem" }}>
              {selected.map((selectedOption) => (
                <Chip variant="soft" color="primary">
                  {selectedOption.label}
                </Chip>
              ))}
            </Box>
          )}
          sx={{ width: "100%" }}
          slotProps={{
            listbox: {
              sx: {
                width: "100%",
              },
            },
          }}
        >
          <Option value="OWNER">OWNER</Option>
          <Option value="ADMIN">ADMIN</Option>
          <Option value="MANAGER">MANAGER</Option>
        </Select>
      );
    },
  },
];

export default function Members() {
  const t = useTranslation();
  const teamCtx = useTeam();

  const userEdges = teamCtx.team.userEdges;

  return (
    <>
      <Box sx={{ display: "flex", m: 2 }}>
        <Typography sx={{ flex: 1 }} level="h2" component="h1">
          {t("Members")}
        </Typography>
        <Button variant="solid">{t("Invite")}</Button>
      </Box>
      <Box
        sx={{
          gap: 2,
          p: 2,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <DataGrid
          rows={userEdges.map((edge) => ({
            roles: edge.roles,
            createdAt: edge.createdAt,
            ...edge.user,
          }))}
          columns={columns}
          editMode="cell"
        />
      </Box>
    </>
  );
}

import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import { Typography } from "@mui/joy";
import { Stack } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import Radio from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import Sheet from "@mui/joy/Sheet";
import Box from "@mui/joy/Box";
import { QCSKULogo } from "../../../components/QCSKULogo";
import { useTranslation } from "../../../i18n";

export default function GetStarted() {
  const t = useTranslation();

  const options = [
    {
      role: "brand",
      text: t("I'm a Brand: Manage inventory & schedule QC inspections."),
    },
    {
      role: "agency",
      text: t("I'm an Agency: Provide QC inspection services."),
    },
  ];

  const [userRole, setUserRole] = useState<"brand" | "agency">("brand");
  const navigate = useNavigate();

  const handleNext = useCallback(() => {
    switch (userRole) {
      case "agency":
        navigate("/create-agency");
        break;
      case "brand":
        navigate("/create-brand");
        break;
    }
  }, [navigate, userRole]);

  return (
    <Stack gap={4} sx={{ mb: 2 }}>
      <Stack gap={1}>
        <Card
          sx={{
            textAlign: "center",
            alignItems: "center",
            width: 343,
            margin: "auto",
            // to make the demo resizable
            overflow: "auto",
            "--icon-size": "100px",
            bgcolor: "background.surface",
          }}
        >
          <CardOverflow variant="soft">
            <QCSKULogo />
          </CardOverflow>

          <Typography
            level="title-lg"
            sx={{ mt: "calc(var(--icon-size) / 2)" }}
          >
            {t("Get Started!")}
          </Typography>
          <CardContent sx={{ maxWidth: "40ch" }}>
            <Typography sx={{ mb: 3 }}>
              {t("Manage inventory or schedule inspections effortlessly.")}
            </Typography>
            <Box sx={{ width: 300 }}>
              <RadioGroup
                defaultValue={userRole}
                size="lg"
                sx={{ gap: 1.5 }}
                onChange={(e) =>
                  setUserRole(e.target.value as "brand" | "agency")
                }
              >
                {options.map(({ role, text }) => (
                  <Sheet
                    key={role}
                    sx={{ p: 2, borderRadius: "md", boxShadow: "sm" }}
                  >
                    <Radio
                      label={text}
                      overlay
                      disableIcon
                      value={role}
                      slotProps={{
                        label: ({ checked }) => ({
                          sx: {
                            fontWeight: "sm",
                            fontSize: "md",
                            color: checked ? "text.primary" : "text.secondary",
                          },
                        }),
                        action: ({ checked }) => ({
                          sx: (theme) => ({
                            ...(checked && {
                              "--variant-borderWidth": "2px",
                              "&&": {
                                // && to increase the specificity to win the base :hover styles
                                borderColor: theme.vars.palette.primary[500],
                              },
                            }),
                          }),
                        }),
                      }}
                    />
                  </Sheet>
                ))}
              </RadioGroup>
            </Box>
          </CardContent>
          <CardActions
            orientation="vertical"
            buttonFlex={1}
            sx={{
              "--Button-radius": "40px",
              width: "clamp(min(100%, 200px), 50%, min(100%, 200px))",
            }}
          >
            <Button variant={"solid"} color={"primary"} onClick={handleNext}>
              {t("Next")}
            </Button>
            <Button
              variant="plain"
              color="primary"
              onClick={() => navigate("/join")}
            >
              {t("Join Brand or Agency")}
            </Button>
          </CardActions>
        </Card>
      </Stack>
    </Stack>
  );
}

import { Box, Button, Typography } from "@mui/joy";
import { useUserMeQuery } from "../../__generated__/types-and-hooks";
import { Stack } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import React, { useCallback } from "react";
import TeamEdgeMiniCard from "./TeamEdgeMiniCard";
import { useTranslation } from "../../i18n";
import MyProfileCard from "../profile/MyProfileCard";

export default function Dashboard() {
  const userMeQuery = useUserMeQuery();
  const navigate = useNavigate();
  const t = useTranslation();

  React.useEffect(() => {
    if (userMeQuery.data?.user_me.teamEdges.length === 0) {
      navigate("/get-started");
    }
  }, [userMeQuery, navigate]);

  const handleGetStarted = useCallback(() => {
    navigate("/get-started");
  }, [navigate]);

  return (
    <>
      <Stack direction="column">
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack direction={"row"}>
            <Typography sx={{ flex: 1 }} level="h4">
              {t("Brands & Agencies")}
            </Typography>
          </Stack>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "grid",
            gridTemplateColumns:
              "repeat(auto-fill, minmax(min(100%, 200px), 1fr))",
            gap: 2,
            p: 2,
          }}
        >
          {userMeQuery.data?.user_me.teamEdges.map((teamEdge, i) => (
            <TeamEdgeMiniCard
              key={i}
              team={teamEdge.team}
              roles={teamEdge.roles}
            />
          ))}
          <Button
            variant="outlined"
            onClick={handleGetStarted}
            sx={{ minHeight: "282px" }}
          >
            <Typography color="primary" level="body-lg">
              {t("Get Started")}
            </Typography>
          </Button>
        </Box>
      </Stack>
      <Stack direction="column">
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack direction={"row"}>
            <Typography sx={{ flex: 1 }} level="h4">
              {t("Users")}
            </Typography>
          </Stack>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "grid",
            gridTemplateColumns:
              "repeat(auto-fill, minmax(min(100%, 200px), 1fr))",
            gap: 2,
            p: 2,
          }}
        >
          <MyProfileCard />
        </Box>
      </Stack>
    </>
  );
}

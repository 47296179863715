import * as React from "react";
import { Transition } from "react-transition-group";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import { Button, IconButton, ModalClose, Stack } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/joy/Typography";
import { useTranslation } from "../../../i18n";
import { useTeam } from "../TeamLayout";
import { BehaviorSubject, combineLatest, map, Subject } from "rxjs";
import { computed } from "../../../decorators/computed";
import { useNotify } from "../../../hooks/useNotify";
import OpenInNew from "@mui/icons-material/OpenInNew";
import { XTextInput } from "../../../x-components/XTextInput";
import {
  useAnvylConfigQuery,
  useAnvylConfigUpdateMutation,
} from "../../../__generated__/types-and-hooks";

export interface AnvylConfigurationModalProps {
  open: boolean;
}

class AnvylConfiguration$ {
  constructor(
    public teamId$: Subject<string>,
    public apiKey$: Subject<string>
  ) {}

  @computed
  get teamId$errors$() {
    return this.teamId$.pipe(
      map((teamId) => {
        if (teamId === null || teamId.length === 0) {
          return "TeamId is required.";
        }
        return null;
      })
    );
  }

  @computed
  get apiKey$errors$() {
    return this.apiKey$.pipe(
      map((apiKey) => {
        if (apiKey === null || apiKey.length === 0) {
          return "API Key is required.";
        }
        return null;
      })
    );
  }

  @computed
  get json$() {
    return combineLatest({
      teamId: this.teamId$,
      apiKey: this.apiKey$,
    });
  }
}

export default function AnvylConfigurationModal({
  open,
}: AnvylConfigurationModalProps) {
  const ctx = useTeam();
  const navigate = useNavigate();
  const notify = useNotify();
  const t = useTranslation();
  const anvylQuery = useAnvylConfigQuery({
    variables: { teamId: ctx.team.id },
  });
  const [updateAnvylConfig] = useAnvylConfigUpdateMutation();

  const anvyl$ = React.useMemo(
    () =>
      new AnvylConfiguration$(
        new BehaviorSubject<string>(
          anvylQuery.data?.anvyl_config?.anvylTeamId.toString() ?? ""
        ),
        new BehaviorSubject<string>(
          anvylQuery.data?.anvyl_config?.anvylApiKey ?? ""
        )
      ),
    [anvylQuery]
  );

  const [saving, setSaving] = React.useState(false);

  const [showErrors, setShowErrors] = React.useState(false);
  const handleSave = React.useCallback(async () => {
    setShowErrors(true);
    anvyl$.json$
      .subscribe({
        next: async (data) => {
          setSaving(true);

          try {
            const res = await updateAnvylConfig({
              variables: {
                teamId: ctx.team.id,
                anvylTeamId: parseInt(data.teamId, 10),
                anvylApiKey: data.apiKey,
                enabled: true,
              },
            });

            setSaving(false);
            if (res.errors) {
              notify({ type: "danger", msg: "An error has been occurred." });
            } else {
              navigate("..", {
                relative: "path",
              });
              notify({
                type: "success",
                msg: "Anvyl configuration has been updated.",
              });
            }
          } catch (e) {
            console.error(e);
            notify({ type: "danger", msg: "An error has been occurred." });
          }
        },
      })
      .unsubscribe();
  }, [anvyl$, ctx.team.id, navigate, notify, updateAnvylConfig]);

  React.useEffect(() => {
    const handleEnterEsc = async (event: any) => {
      if (event.key === "Escape") {
        navigate("..", {
          relative: "path",
        });
      } else if (event.key === "Enter") {
        handleSave();
      }
    };
    window.addEventListener("keyup", handleEnterEsc);

    return () => {
      window.removeEventListener("keyup", handleEnterEsc);
    };
  }, [navigate, open, handleSave]);
  return (
    <React.Fragment>
      <Transition in={open} timeout={400}>
        {(state: string) => (
          <Modal
            keepMounted
            open={!["exited", "exiting"].includes(state)}
            slotProps={{
              backdrop: {
                sx: {
                  opacity: 0,
                  backdropFilter: "none",
                  transition: `opacity 200ms, backdrop-filter 200ms ease-in-out`,
                  ...{
                    entering: { opacity: 1, backdropFilter: "blur(8px)" },
                    entered: { opacity: 1, backdropFilter: "blur(8px)" },
                  }[state],
                },
              },
            }}
            sx={[
              state === "exited"
                ? { visibility: "hidden" }
                : { visibility: "visible" },
            ]}
          >
            <ModalDialog
              sx={{
                opacity: 0,
                transition: `opacity 150ms ease-in-out`,
                ...{
                  entering: { opacity: 1 },
                  entered: { opacity: 1 },
                }[state],
              }}
            >
              <ModalClose
                onClick={() =>
                  navigate("..", {
                    relative: "path",
                  })
                }
              />
              <DialogTitle>{t("Anvyl Configuration")}</DialogTitle>
              {anvylQuery.data && (
                <DialogContent>
                  <Stack gap={4} sx={{ mt: 2 }}>
                    <Typography>
                      <IconButton
                        aria-label="Open in new tab"
                        component="a"
                        href="https://developer.anvyl.com/docs/generating-an-api-key"
                        target="_blank"
                        variant="plain"
                        color="primary"
                        size="sm"
                        sx={{ p: 1 }}
                      >
                        Learn how to generate team_id and API Key
                        <OpenInNew />
                      </IconButton>
                    </Typography>

                    <XTextInput
                      autoFocus
                      label={t("Team ID")}
                      showError={showErrors}
                      required
                      val$={anvyl$.teamId$}
                      error$={anvyl$.teamId$errors$}
                    />

                    <XTextInput
                      label={t("API Key")}
                      showError={showErrors}
                      required
                      val$={anvyl$.apiKey$}
                      error$={anvyl$.apiKey$errors$}
                    />

                    <Stack gap={4} sx={{ mt: 2 }}>
                      <Button
                        disabled={saving}
                        loading={saving}
                        type="submit"
                        fullWidth
                        onClick={handleSave}
                      >
                        {t("Save")}
                      </Button>
                    </Stack>
                  </Stack>
                </DialogContent>
              )}
            </ModalDialog>
          </Modal>
        )}
      </Transition>
    </React.Fragment>
  );
}

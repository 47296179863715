import React from "react";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import { Typography } from "@mui/joy";
import { Stack } from "@mui/joy";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "../../../i18n";
import { QCSKULogo } from "../../../components/QCSKULogo";
import Confetti from "react-confetti";

interface NextStepsProps {
  text: string;
}

export default function NextSteps({ text }: NextStepsProps) {
  const { state } = useLocation();
  const [numberOfPieces, setNumberOfPieces] = React.useState(1000);
  const navigate = useNavigate();
  const t = useTranslation();

  React.useEffect(() => {
    setTimeout(() => setNumberOfPieces(0), 2000);
  }, [numberOfPieces]);

  return (
    <>
      <Confetti numberOfPieces={numberOfPieces} />

      <Stack gap={4} sx={{ mb: 2 }}>
        <Stack gap={1}>
          <Card
            variant="outlined"
            sx={{
              boxShadow: "sm",
              textAlign: "center",
              alignItems: "center",
              width: 343,
              margin: "auto",
              // to make the demo resizable
              overflow: "auto",
              "--icon-size": "100px",
              bgcolor: "background.surface",
            }}
          >
            <CardOverflow variant="soft">
              <QCSKULogo />
            </CardOverflow>

            <Typography
              textColor="success.500"
              level="title-lg"
              sx={{ mt: "calc(var(--icon-size) / 2)" }}
            >
              {t("Congrats!")}
            </Typography>
            <CardContent>
              <Typography>{state.text ?? text}</Typography>
            </CardContent>
            <CardActions
              orientation="vertical"
              buttonFlex={1}
              sx={{
                "--Button-radius": "40px",
                width: "clamp(min(100%, 200px), 50%, min(100%, 200px))",
              }}
            >
              {state.primaryAction && (
                <Button
                  variant="solid"
                  color="primary"
                  onClick={() => navigate(state.primaryAction.link)}
                >
                  {state.primaryAction.text}
                </Button>
              )}
              <Button
                variant="plain"
                color="primary"
                onClick={() => navigate("/")}
              >
                {t("Go Back")}
              </Button>
            </CardActions>
          </Card>
        </Stack>
      </Stack>
    </>
  );
}

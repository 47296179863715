import { useContext } from "react";
import { useRxVal } from "../hooks/useRx";
import { AppContext } from "../contexts/AppContext";
import { Snackbar } from "@mui/joy";

export default function Notifications() {
  const app = useContext(AppContext);
  const notification = useRxVal(app.notificationsBuffer$);
  if (!notification) return null;

  return (
    <Snackbar
      variant="soft"
      color={notification.type}
      open
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      {notification.msg}
    </Snackbar>
  );
}

import "@fontsource/inter";
import "./index.css";
import App from "./App";
import { createBrowserRouter } from "react-router-dom";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import NextSteps from "./pages/auth/NextSteps";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Dashboard from "./pages/dash/Dashboard";
import Authorization from "./pages/auth/Authorization";
import ProfileSettings from "./pages/profile/Settings";
import DashboardLayout from "./pages/dash/DashboardLayout";
import CreateTeam from "./pages/dash/get-started/CreateTeam";
import GetStarted from "./pages/dash/get-started/GetStarted";
import JoinTeam from "./pages/dash/get-started/JoinTeam";
import DashNextSteps from "./pages/dash/get-started/NextSteps";
import { teamRoutes } from "./pages/team/routes";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <DashboardLayout />,
        children: [
          {
            path: "/",
            element: <Dashboard />,
          },
          {
            path: "/get-started",
            element: <GetStarted />,
          },
          {
            path: "/create-brand",
            element: <CreateTeam kind="brand" />,
          },
          {
            path: "/create-agency",
            element: <CreateTeam kind="agency" />,
          },
          {
            path: "/join",
            element: <JoinTeam />,
          },
          {
            path: "/next-steps",
            element: <DashNextSteps text="" />,
          },
          {
            path: "/me",
            element: <ProfileSettings />,
          },
        ],
      },
      // {
      //   path: "/t",
      //   element: <MainLayout />,
      //   children: [
      //     {
      //       path: "/t/:teamSlug",
      //       element: <TeamInfo />,
      //     },
      //   ],
      // },
      // { ...agencyRoutes },
      { ...teamRoutes },
      // {
      //   path: "/team",
      //   element: <MainLayout />,
      //   children: [
      //     {
      //       path: "/team/new",
      //       element: <NewTeam />,
      //     },
      //     {
      //       path: "/team/:teamSlug",
      //       element: <NewTeam />,
      //     },
      //   ],
      // },
      // {
      //   path: "/profile",
      //   element: <MainLayout />,
      //   children: [
      //     {
      //       path: "/profile",
      //       element: <ProfileSettings />,
      //     },
      //     {
      //       path: "/profile/:userId",
      //       element: <ProfileSettings />,
      //     },
      //   ],
      // },
    ],
  },
  /** Sign Up/In */
  {
    path: "/auth",
    element: <Authorization />,
    children: [
      {
        path: "/auth/sign-in",
        element: <SignIn />,
      },
      {
        path: "/auth/sign-up",
        element: <SignUp />,
      },
      {
        path: "/auth/next-steps",
        element: <NextSteps />,
      },
      {
        path: "/auth/forgot-password",
        element: <ForgotPassword />,
      },
    ],
  },
]);

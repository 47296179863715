import { Subject } from "rxjs";
import { computed } from "../decorators/computed";

export type Notification = {
  type: "success" | "danger" | "warning" | "neutral";
  msg: string;
};

const NOTIFICATION_DISPLAY_TIMEOUT = 5000;

export class XApp {
  notifications$: Subject<Notification | null>;
  timeoutId: NodeJS.Timeout | null = null;

  constructor() {
    this.notifications$ = new Subject();
  }

  next(notification: Notification) {
    this.timeoutId && clearTimeout(this.timeoutId);
    this.notifications$.next(notification);
    this.timeoutId = setTimeout(() => {
      this.notifications$.next(null);
    }, NOTIFICATION_DISPLAY_TIMEOUT);
  }

  @computed
  get notificationsBuffer$() {
    return this.notifications$;
  }
}

import { Box, Typography } from "@mui/joy";

export function Footer() {
  return (
    <Box component="footer" sx={{ py: 3 }}>
      <Typography level="body-xs" textAlign="center">
        © QCSKU {new Date().getFullYear()}
      </Typography>
    </Box>
  );
}

import {
  FormControl,
  FormHelperText,
  FormLabel,
  Textarea,
  TextareaTypeMap,
} from "@mui/joy";
import { ReactNode, useCallback } from "react";
import { Observable, Subject } from "rxjs";
import { useRxVal } from "../hooks/useRx";

export interface TextArea$Props {
  label?: string | ReactNode;
  val$: Subject<string | null>;
  error$?: Observable<string | null>;
  showError?: boolean;
  required?: boolean;
}

export function XTextArea({
  val$,
  error$,
  showError,
  label,
  required,
  ...props
}: TextareaTypeMap<{}, "div">["props"] & TextArea$Props) {
  const val = useRxVal(val$);
  const error = useRxVal(error$);

  const handleChanges = useCallback(
    (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
      console.log("e", e.target.value);
      val$.next(e.target.value);
    },
    [val$]
  );

  return (
    <FormControl required={required} error={showError && !!error}>
      {label && <FormLabel>{label}</FormLabel>}
      <Textarea
        {...props}
        onChange={handleChanges}
        onBlur={handleChanges}
        value={val ?? ""}
      />
      {showError && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
    // // <textarea defaultValue={val} onBlur={handleChanges} />
    // <Textarea
    //   defaultValue={val}
    //   // onChange={handleChanges}
    //   onBlur={handleChanges}
    //   // slots={{
    //   //   textarea: {
    //   //     // @ts-ignore
    //   //     onBlur: handleChanges,
    //   //   },
    //   // }}
    //   {...props}
    // />
  );
}

import { DraftVM } from "./DraftVM";
import { XTextInput } from "../../../../x-components/XTextInput";
import XCountrySelect from "../../../../x-components/XCountrySelect";
import { useTranslation } from "../../../../i18n";
import { SupplierAutocomplete } from "./SupplierAutocomplete";
import { useTeam } from "../../TeamLayout";
import { useEffect } from "react";

export default function StepInspectionType({ vm }: { vm: DraftVM }) {
  const teamCtx = useTeam();
  const t = useTranslation();

  useEffect(() => {
    const sub = vm.supplier$.subscribe((supplier) => {
      vm.addressLine1$.next(supplier?.address?.address_line1 ?? "");
      vm.addressLine2$.next(supplier?.address?.address_line2 ?? "");
      vm.city$.next(supplier?.address?.city ?? "");
      vm.state$.next(supplier?.address?.state ?? "");
      vm.postalCode$.next(supplier?.address?.postal_code ?? "");
      vm.country$.next(supplier?.address?.country ?? "");
    });

    return () => sub.unsubscribe();
  });

  return (
    <>
      <SupplierAutocomplete
        required
        label={t("Supplier")}
        teamId={teamCtx.team.id}
        val$={vm.supplier$}
      />
      <XTextInput
        label={t("Address Line 1")}
        required
        val$={vm.addressLine1$}
      />
      <XTextInput label={t("Address Line 2")} val$={vm.addressLine2$} />
      {/* <XTextInput label="District" required val$={vm.district$} /> */}
      <XTextInput label={t("State (province)")} required val$={vm.state$} />
      <XTextInput label={t("City")} required val$={vm.city$} />
      <XTextInput label={t("Postal Code")} required val$={vm.postalCode$} />
      <XCountrySelect label={t("Country")} required val$={vm.country$} />
    </>
  );
}

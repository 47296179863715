import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Avatar from "@mui/joy/Avatar";
import Dropdown from "@mui/joy/Dropdown";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import ListDivider from "@mui/joy/ListDivider";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { useUserMeQuery } from "../../__generated__/types-and-hooks";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "../../i18n";

export default function ProfileDropDown() {
  const navigate = useNavigate();
  const myProfile = useUserMeQuery();
  const t = useTranslation();

  return (
    <Dropdown>
      <MenuButton
        variant="soft"
        size="sm"
        sx={{
          maxWidth: "32px",
          maxHeight: "32px",
          borderRadius: "50%",
        }}
      >
        <Avatar
          src={
            process.env.REACT_APP_API_URL +
            "/" +
            myProfile?.data?.user_me.picture
          }
          sx={{ maxWidth: "30px", maxHeight: "30px" }}
        />
      </MenuButton>
      <Menu
        placement="bottom-end"
        size="sm"
        sx={{
          zIndex: "99999",
          p: 1,
          gap: 1,
          "--ListItem-radius": "var(--joy-radius-sm)",
        }}
      >
        <MenuItem onClick={() => navigate("/me")}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Avatar
              src={
                process.env.REACT_APP_API_URL +
                  "/" +
                  myProfile.data?.user_me.picture ?? ""
              }
              sx={{ borderRadius: "50%" }}
            />
            {myProfile.data && (
              <Box sx={{ ml: 1.5 }}>
                <Typography level="title-sm" textColor="text.primary">
                  {myProfile.data.user_me.firstName}{" "}
                  {myProfile.data.user_me.lastName}
                </Typography>
                <Typography level="body-xs" textColor="text.tertiary">
                  {myProfile.data.user_me.email}
                </Typography>
              </Box>
            )}
          </Box>
        </MenuItem>
        <ListDivider />
        <MenuItem
          component={Link}
          target="_blank"
          to={process.env.REACT_APP_HELP_LINK as string}
        >
          <HelpRoundedIcon />
          {t("Help")}
          <OpenInNewRoundedIcon />
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.location.href = "http://localhost:8088/auth/logout";
          }}
        >
          <LogoutRoundedIcon />
          {t("Log out")}
        </MenuItem>
      </Menu>
    </Dropdown>
  );
}

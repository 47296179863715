import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardActions from "@mui/joy/CardActions";
import Typography from "@mui/joy/Typography";
import { AspectRatio, CardOverflow, Chip, useColorScheme } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../i18n";
import { DateTime } from "../../components/DateTime";

export interface BottomActionsCardProps {
  team: {
    id: number;
    slug: string;
    name: string;
    picture: string;
    kind: string;
    verified: boolean;
    description?: string | null;
    deleted: boolean;
    createdAt: any;
    createdBy: number;
    updatedAt: any;
    updatedBy: number;
    contacts: Array<{
      __typename?: "ContactMethod";
      type: string;
      value: string;
    }>;
  };
  roles: string[];
}

export default function TeamEdgeMiniCard({
  team,
  roles,
}: BottomActionsCardProps) {
  const { mode } = useColorScheme();
  const navigate = useNavigate();
  const t = useTranslation();
  return (
    <Card
      sx={{
        textAlign: "center",
        alignItems: "center",
        width: "100%",
        margin: "auto",
        // to make the demo resizable
        overflow: "auto",
        "--icon-size": "80px",
        bgcolor: team.kind === "AGENCY" ? "danger.softBg" : "primary.softBg",
        borderColor:
          team.kind === "AGENCY"
            ? "danger.outlinedBorder"
            : "primary.outlinedBorder",
      }}
    >
      <CardOverflow
        variant="soft"
        sx={{
          bgcolor:
            team.kind === "AGENCY"
              ? "danger.outlinedBorder"
              : "primary.outlinedBorder",
        }}
      >
        <AspectRatio
          variant="outlined"
          ratio="1"
          sx={{
            m: "auto",
            transform: "translateY(50%)",
            borderRadius: "50%",
            width: "var(--icon-size)",
            boxShadow: "sm",
            bgcolor: "background.surface",
            position: "relative",
          }}
        >
          <img
            height="100px"
            src={
              team.picture && team.picture.length > 0
                ? team.picture
                : mode === "light"
                ? "/images/qcsku_light.jpg"
                : "/images/qcsku_dark.jpg"
            }
            alt=""
          />
        </AspectRatio>
      </CardOverflow>
      <CardContent
        sx={{
          maxWidth: "40ch",
          mt: "calc(var(--icon-size) / 2)",
        }}
      >
        <div>
          <Typography level="title-lg">{team.name}</Typography>
          <Typography level="body-sm">
            {t("Created at ")} <DateTime value={team.createdAt} />
          </Typography>
        </div>
        <Typography level="body-sm">
          {t("Roles")}{" "}
          {roles.map((role) => (
            <Chip>{role}</Chip>
          ))}
        </Typography>
      </CardContent>
      <CardActions
        orientation="horizontal"
        buttonFlex={1}
        sx={{
          width: "clamp(min(100%, 200px), 50%, min(100%, 200px))",
        }}
      >
        {/* <IconButton variant="outlined" color="neutral" sx={{ mr: "auto" }}>
          <FavoriteBorder />
        </IconButton> */}
        <Button
          variant="outlined"
          color="primary"
          sx={{
            borderColor:
              team.kind === "AGENCY"
                ? "danger.solidBg"
                : "primary.outlinedColor",
            color:
              team.kind === "AGENCY"
                ? "danger.outlinedColor"
                : "primary.outlinedColor",
            ":hover": {
              bgcolor:
                team.kind === "AGENCY"
                  ? "danger.softHoverBg"
                  : "primary.softHoverBg",
            },
          }}
          onClick={() => navigate("/" + team.slug)}
        >
          {t("Open")}
        </Button>
      </CardActions>
    </Card>
  );
}

import FormLabel from "@mui/joy/FormLabel";
import Radio, { radioClasses } from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import Sheet from "@mui/joy/Sheet";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { ForwardedRef, forwardRef, ReactNode } from "react";
import { Box, FormHelperText } from "@mui/joy";

export type IconsRadioProps = {
  name: string;
  options: {
    label: string;
    description?: string;
    value: string;
    icon: ReactNode;
  }[];
  defaultValue?: string;
  value?: string;
  onChange?: (arg: React.ChangeEvent<HTMLInputElement>) => void;
};

export default forwardRef(function IconsRadio(
  { options, defaultValue, name, onChange, value }: IconsRadioProps,
  ref: ForwardedRef<any>
) {
  return (
    <RadioGroup
      onChange={onChange}
      ref={ref}
      aria-label={name}
      defaultValue={defaultValue}
      value={value}
      overlay
      name={name}
      sx={{
        flexDirection: "row",
        flexWrap: "wrap",
        gap: 2,
        [`& .${radioClasses.checked}`]: {
          [`& .${radioClasses.action}`]: {
            inset: -1,
            border: "3px solid",
            borderColor: "primary.500",
          },
        },
        [`& .${radioClasses.radio}`]: {
          display: "contents",
          "& > svg": {
            zIndex: 2,
            position: "absolute",
            top: "-8px",
            right: "-8px",
            bgcolor: "background.surface",
            borderRadius: "50%",
          },
        },
      }}
    >
      {options.map(({ label, value, icon, description }) => (
        <Sheet
          key={value}
          variant="outlined"
          sx={{
            borderRadius: "md",
            boxShadow: "sm",
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
            gap: 1.5,
            p: 2,
            minWidth: 120,
            maxWidth: 360,
          }}
        >
          <Radio
            id={value}
            value={value}
            checkedIcon={<CheckCircleRoundedIcon />}
          />
          {icon}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <FormLabel htmlFor={value}>{label}</FormLabel>
            {description && (
              <FormHelperText sx={{}}>{description}</FormHelperText>
            )}
          </Box>
        </Sheet>
      ))}
    </RadioGroup>
  );
});

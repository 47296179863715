import Settings from "@mui/icons-material/Settings";
import { Box, Chip, Skeleton, Stack, Typography } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { useTeam } from "../TeamLayout";
import { useAnvylConfigQuery } from "../../../__generated__/types-and-hooks";
import { ConfigurationCard } from "./ConfigurationCard";

const whatIsAnvyl = `Anvyl is a supply chain visibility platform that helps consumer
            brands track, manage, and automate their supply chain from pre-PO
            issuance to warehouse delivery. Built for operational and production
            teams, Anvyl improves efficiencies by reducing manual entry,
            automating workflows, and driving collaboration.`;

function AnvylCard() {
  const navigate = useNavigate();
  const ctx = useTeam();
  const anvylQuery = useAnvylConfigQuery({
    variables: { teamId: ctx.team.id },
  });

  return (
    <ConfigurationCard
      title="Anvyl"
      link="https://anvyl.com"
      onClick={() => navigate("anvyl")}
      status={
        <>
          <Typography level="title-md" sx={{ mr: "auto" }}>
            Status{" "}
            {anvylQuery.data?.anvyl_config?.enabled && (
              <Chip color="success">
                <Typography textColor="text.tertiary" sx={{ fontSize: "sm" }}>
                  Online
                </Typography>
              </Chip>
            )}
            {anvylQuery.data?.anvyl_config?.enabled === false && (
              <Chip color="danger">
                <Typography textColor="text.tertiary" sx={{ fontSize: "sm" }}>
                  Offline
                </Typography>
              </Chip>
            )}
          </Typography>
        </>
      }
      buttonText="Configuration"
      buttonStartDecorator={<Settings />}
    >
      <Stack gap={1}>
        {anvylQuery.loading && (
          <Typography level="body-sm">
            <Skeleton>{whatIsAnvyl}</Skeleton>
          </Typography>
        )}
        {anvylQuery.data?.anvyl_config === null && (
          <Typography level="body-sm">{whatIsAnvyl}</Typography>
        )}
        {anvylQuery.data?.anvyl_config && (
          <>
            <Typography level="title-sm">
              Team ID:{" "}
              <Typography textColor="text.tertiary" sx={{ fontSize: "sm" }}>
                {anvylQuery.data?.anvyl_config.anvylTeamId}
              </Typography>
            </Typography>
            <Typography level="title-sm">
              API Key:{" "}
              <Typography textColor="text.tertiary" sx={{ fontSize: "sm" }}>
                {anvylQuery.data?.anvyl_config.anvylApiKey.slice(0, 5) +
                  "..." +
                  anvylQuery.data?.anvyl_config.anvylApiKey.slice(-5)}
              </Typography>
            </Typography>
          </>
        )}
      </Stack>
    </ConfigurationCard>
  );
}

const whatIsNetSuite = `The #1 Cloud ERP. An AI-powered business management suite, encompassing ERP/Financials, CRM, and ecommerce for more than 40,000 customers.`;

function NetSuiteCard() {
  const navigate = useNavigate();
  return (
    <ConfigurationCard
      title="NetSuite"
      link="https://www.netsuite.com"
      onClick={() => navigate("netsuite")}
      buttonText="Configuration"
      buttonStartDecorator={<Settings />}
      disabled
    >
      <Typography level="body-sm">{whatIsNetSuite}</Typography>
    </ConfigurationCard>
  );
}

export default function Integrations() {
  return (
    <Box
      sx={{
        width: "100%",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(min(100%, 300px), 1fr))",
        gap: 2,
        p: 2,
      }}
    >
      <AnvylCard />
      <NetSuiteCard />
      {/* 
        <Salesforce /> 
        <Shopify />
      */}
    </Box>
  );
}

import * as React from "react";
import Button from "@mui/joy/Button";
import Stack from "@mui/joy/Stack";
import { XTextInput } from "../../x-components/XTextInput";
import { useTranslation } from "../../i18n";
import { computed } from "../../decorators/computed";
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  map,
  Subject,
} from "rxjs";
import { XCountry } from "../../x-components/XCountry";
import {
  User,
  useUserUpdateMutation,
} from "../../__generated__/types-and-hooks";

export class UserProfileVM {
  public firstName$: Subject<string>;
  public lastName$: Subject<string>;
  public countryCode$: Subject<string>;

  constructor(
    public user: Pick<User, "firstName" | "lastName" | "country" | "id">
  ) {
    this.firstName$ = new BehaviorSubject(user.firstName);
    this.lastName$ = new BehaviorSubject(user.lastName);
    this.countryCode$ = new BehaviorSubject(user.country.code);
  }

  @computed
  get firstName$error$() {
    return this.firstName$.pipe(
      map((firstName) => {
        if (firstName === null || firstName.length === 0) {
          return "First Name is required.";
        }
        return null;
      })
    );
  }

  @computed
  get lastName$error$() {
    return this.lastName$.pipe(
      map((lastName) => {
        if (lastName === null || lastName.length === 0) {
          return "Last Name is required.";
        }
        return null;
      })
    );
  }

  @computed
  get json$() {
    return combineLatest({
      firstName: this.firstName$,
      lastName: this.lastName$,
      countryCode: this.countryCode$,
    });
  }
}

export interface UserProfileFormProps {
  vm: UserProfileVM;
}

export default function UserProfileForm({ vm }: UserProfileFormProps) {
  const [showError] = React.useState<boolean>(false);
  const t = useTranslation();
  const [updateUser, { error }] = useUserUpdateMutation();

  const onSubmit = React.useCallback(
    async (e: any) => {
      e.preventDefault();

      const { firstName, lastName, countryCode } = await firstValueFrom(
        vm.json$
      );

      await updateUser({
        variables: {
          id: vm.user.id,
          firstName,
          lastName,
          countryCode,
        },
        update: (cache, { data }) => {
          cache.evict({ id: `User:${vm.user.id}` });
        },
      });
    },
    [vm, updateUser]
  );

  return (
    <form>
      <XTextInput
        required
        label={t("First Name")}
        size="sm"
        placeholder="First Name"
        val$={vm.firstName$}
        error$={vm.firstName$error$}
        showError={showError}
      />
      <XTextInput
        required
        label={t("Last Name")}
        size="sm"
        placeholder="Last Name"
        val$={vm.lastName$}
        error$={vm.lastName$error$}
        showError={showError}
      />
      <XCountry required label={t("Country")} val$={vm.countryCode$} />

      <Stack gap={4} sx={{ mt: 2 }}>
        <Button onClick={onSubmit} type="submit" fullWidth>
          {t("Save")}
        </Button>
        {error && JSON.stringify(error)}
      </Stack>
    </form>
  );
}

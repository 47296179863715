import { AspectRatio, useColorScheme } from "@mui/joy";

export function QCSKULogo() {
  const { mode } = useColorScheme();
  return (
    <AspectRatio
      variant="outlined"
      ratio="1"
      sx={{
        m: "auto",
        transform: "translateY(50%)",
        borderRadius: "50%",
        width: "var(--icon-size)",
        boxShadow: "sm",
        bgcolor: "background.surface",
        position: "relative",
      }}
    >
      {mode === "dark" && (
        <img height="100px" src="/images/qcsku_dark.jpg" alt="" />
      )}
      {mode === "light" && (
        <img height="100px" src="/images/qcsku_light.jpg" alt="" />
      )}
    </AspectRatio>
  );
}

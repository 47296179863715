import * as React from "react";
import Button from "@mui/joy/Button";
import Stack from "@mui/joy/Stack";
import { XTextInput } from "../../x-components/XTextInput";
import EmailIcon from "@mui/icons-material/Email";
import PasswordIcon from "@mui/icons-material/Password";
import { useRxVal } from "../../hooks/useRx";
import { Alert } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../i18n";
import { computed } from "../../decorators/computed";
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  map,
  Subject,
} from "rxjs";
import { accessTokenManager } from "../../models/AccessTokenManager";
import { XCountry } from "../../x-components/XCountry";

export class SignUpVM {
  public email$: Subject<string> = new BehaviorSubject("");
  public password$: Subject<string> = new BehaviorSubject("");
  public confirmPassword$: Subject<string> = new BehaviorSubject("");
  public firstName$: Subject<string> = new BehaviorSubject("");
  public lastName$: Subject<string> = new BehaviorSubject("");
  public countryCode$: Subject<string> = new BehaviorSubject("US");

  public status$ = new BehaviorSubject<{
    status: "ready" | "pending" | "error";
    error?: string;
  }>({ status: "ready" });

  @computed
  get firstName$error$() {
    return this.firstName$.pipe(
      map((firstName) => {
        if (firstName === null || firstName.length === 0) {
          return "First Name is required.";
        }
        return null;
      })
    );
  }

  @computed
  get lastName$error$() {
    return this.lastName$.pipe(
      map((lastName) => {
        if (lastName === null || lastName.length === 0) {
          return "Last Name is required.";
        }
        return null;
      })
    );
  }

  @computed
  get email$error$() {
    return this.email$.pipe(
      map((email) => {
        if (email === null || email.length === 0) {
          return "Email is required.";
        }
        return null;
      })
    );
  }

  @computed
  get password$error$() {
    return this.password$.pipe(
      map((password) => {
        if (password === null || password.length === 0) {
          return "Password is required.";
        }
        return null;
      })
    );
  }

  @computed
  get confirmPassword$error$() {
    return combineLatest({
      confirmPassword: this.confirmPassword$,
      password: this.password$,
    }).pipe(
      map(({ password, confirmPassword }) => {
        if (confirmPassword !== password) {
          return "Password doesn't match.";
        }
        return null;
      })
    );
  }

  @computed
  get json$() {
    return combineLatest({
      email: this.email$,
      password: this.password$,
      firstName: this.firstName$,
      lastName: this.lastName$,
      countryCode: this.countryCode$,
    });
  }

  async signUp(): Promise<boolean> {
    try {
      const { email, password, firstName, lastName, countryCode } =
        await firstValueFrom(this.json$);
      this.status$.next({ status: "pending" });
      await accessTokenManager.signUp({
        email,
        password,
        firstName,
        lastName,
        countryCode,
      });
      this.status$.next({ status: "ready" });
      return true;
    } catch (e: any) {
      console.error(e.message);
      this.status$.next({ status: "error", error: e.message });
    }
    return false;
  }
}

export interface SignUpFormProps {
  vm: SignUpVM;
}

export default function SignUpForm({ vm }: SignUpFormProps) {
  const navagate = useNavigate();
  const [showError, setShowError] = React.useState<boolean>(false);
  const status = useRxVal(vm.status$);
  const t = useTranslation();

  const onSubmit = React.useCallback(
    async (e: any) => {
      e.preventDefault();

      setShowError(true);
      if (await vm.signUp()) {
        navagate("/auth/next-steps");
      }
    },
    [vm, navagate]
  );

  return (
    <form>
      <XTextInput
        required
        label={t("First Name")}
        size="sm"
        placeholder="First Name"
        val$={vm.firstName$}
        error$={vm.firstName$error$}
        showError={showError}
      />
      <XTextInput
        required
        label={t("Last Name")}
        size="sm"
        placeholder="Last Name"
        val$={vm.lastName$}
        error$={vm.lastName$error$}
        showError={showError}
      />
      <XTextInput
        required
        label={t("Email")}
        size="sm"
        placeholder="email"
        type="email"
        startDecorator={<EmailIcon />}
        val$={vm.email$}
        error$={vm.email$error$}
        showError={showError}
      />
      <XTextInput
        required
        label={t("Password")}
        size="sm"
        placeholder="password"
        type="password"
        startDecorator={<PasswordIcon />}
        val$={vm.password$}
        error$={vm.password$error$}
        showError={showError}
      />
      <XTextInput
        required
        label={t("Confirm Password")}
        size="sm"
        placeholder="confirm password"
        type="password"
        startDecorator={<PasswordIcon />}
        val$={vm.confirmPassword$}
        error$={vm.confirmPassword$error$}
        showError={showError}
      />
      <XCountry required label={t("Country")} val$={vm.countryCode$} />

      <Stack gap={4} sx={{ mt: 2 }}>
        <Button onClick={onSubmit} type="submit" fullWidth>
          {t("Sign Up Now")}
        </Button>
        <Alert
          sx={{
            opacity: status.status === "error" ? 1 : 0,
            transition: "opacity 0.3",
          }}
          color="danger"
        >
          {status.error ?? "Invalid email or password"}
        </Alert>
      </Stack>
    </form>
  );
}

import React, { useContext } from "react";
import enUS from "./locales/en-US/webui.json";
import zhCN from "./locales/zh-CN/webui.json";
import ruRU from "./locales/ru-RU/webui.json";
import * as datefns from "date-fns/locale";

export const LangContext = React.createContext<{
  data: {};
  lang: string;
  locale: typeof datefns.enUS;
  setLang: (lang: string) => void;
} | null>(null);

export type LanguageProviderProps = {
  children: React.ReactNode;
};

const langKey = "i18n";

function getLang(): string {
  return localStorage.getItem(langKey) ?? window.navigator.language;
}

const langs: Record<string, object> = {
  "en-US": enUS,
  "zh-CN": zhCN,
  "ru-RU": ruRU,
};

const locales: Record<string, typeof datefns.enUS> = {
  "en-US": datefns.enUS,
  "zh-CN": datefns.zhCN,
  "ru-RU": datefns.ru,
};

export function LanguageProvider({ children }: LanguageProviderProps) {
  const [lang, setLang] = React.useState(getLang());
  const [data, setData] = React.useState(langs[lang]);
  const [locale, setLocale] = React.useState(locales[lang]);

  return (
    <LangContext.Provider
      value={{
        lang,
        locale,
        data,
        setLang: (lang: string) => {
          setLang(lang);
          setLocale(locales[lang]);
          setData(langs[lang]);
          localStorage.setItem(langKey, lang);
        },
      }}
    >
      {children}
    </LangContext.Provider>
  );
}

export function useLocale() {
  const { locale } = useContext(LangContext)!;
  return locale;
}

export function useLang(): [string, (lang: string) => void] {
  const { lang, setLang } = useContext(LangContext)!;
  return [lang, setLang];
}

(window as any).missed = {};

export function useTranslation() {
  const { data } = useContext(LangContext)!;

  return (text: string, variables: Record<string, number | string> = {}) => {
    const d = data as any;
    if (!d[text]) {
      (window as any).missed[text] = text;
    }
    let str = d[text] ?? text;
    for (const key of Object.keys(variables)) {
      str = str.replace("{" + key + "}", variables[key]);
    }
    return str;
  };
}

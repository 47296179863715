import {
  AspectRatio,
  Box,
  Card,
  CardContent,
  CardOverflow,
  IconButton,
} from "@mui/joy";
import UserProfileForm, { UserProfileVM } from "./UserProfileForm";
import { useUserMeQuery } from "../../__generated__/types-and-hooks";
import FileUploadButton from "../../components/FileUploadButton";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";

export default function Settings() {
  const userMeQuery = useUserMeQuery();
  const user = userMeQuery.data?.user_me;
  if (!user) return null;

  return (
    <Box
      component="main"
      sx={{
        my: "auto",
        py: 2,
        pb: 5,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: 400,
        maxWidth: "100%",
        mx: "auto",
        borderRadius: "sm",
        "& form": {
          display: "flex",
          flexDirection: "column",
          gap: 2,
        },
        [`& .MuiFormLabel-asterisk`]: {
          visibility: "hidden",
        },
      }}
    >
      <Card
        sx={{
          textAlign: "center",
          alignItems: "center",
          width: "100%",
          // maxWidth: "220px",
          margin: "auto",
          // to make the demo resizable
          overflow: "auto",
          "--icon-size": "80px",
          bgcolor: "background.surface",
        }}
      >
        <CardOverflow variant="soft">
          <AspectRatio
            variant="outlined"
            ratio="1"
            sx={{
              m: "auto",
              transform: "translateY(50%)",
              borderRadius: "50%",
              width: "var(--icon-size)",
              boxShadow: "sm",
              bgcolor: "background.surface",
              position: "relative",
            }}
          >
            <img
              src={process.env.REACT_APP_API_URL + "/" + (user.picture ?? "")}
              loading="lazy"
              alt=""
            />
          </AspectRatio>
          <IconButton
            component={"label"}
            variant="outlined"
            color="neutral"
            sx={{
              backdropFilter: "blur(10px)",
              border: 0,
              position: "absolute",
              left: 220,
              top: 85,
              borderRadius: "50%",
            }}
          >
            <>
              <FileUploadButton
                entityType="avatar"
                aria-label="Upload"
                size="sm"
                variant="outlined"
                color="neutral"
                component="label"
              />
              <FileUploadRoundedIcon />
            </>
          </IconButton>
        </CardOverflow>
        <CardContent
          sx={{ maxWidth: "40ch", mt: "calc(var(--icon-size) / 2)" }}
        >
          {user && <UserProfileForm vm={new UserProfileVM(user)} />}
        </CardContent>
      </Card>
    </Box>
  );
}

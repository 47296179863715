import React from "react";
import { XApp } from "../models/x-app";

const app = new XApp();
export const AppContext = React.createContext<XApp>(app);

export type AppProviderProps = {
  children: React.ReactNode;
};

export function AppProvider({ children }: AppProviderProps) {
  return <AppContext.Provider value={app}>{children}</AppContext.Provider>;
}

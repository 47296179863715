import * as React from "react";
import "./App.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { CssVarsProvider } from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Outlet } from "react-router-dom";
import { onError } from "@apollo/client/link/error";
import { ApolloLink } from "@apollo/client";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  experimental_extendTheme as extendMaterialTheme,
  THEME_ID,
} from "@mui/material/styles";
import { BehaviorSubject } from "rxjs";
import { LiveProvider } from "./contexts/LiveContext";
import { AppProvider } from "./contexts/AppContext";
import Notifications from "./x-components/Notifications";
import { accessTokenManager } from "./models/AccessTokenManager";

const materialTheme = extendMaterialTheme();

const logoutLink = onError((err) => {
  if ((err.networkError as any)?.statusCode === 401) {
    window.location.href = "/auth/sign-in";
  }
});

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }: any) => ({
    headers: {
      authorization: `Bearer ${accessTokenManager.retrieve()}`,
      ...headers,
    },
  }));
  return forward(operation);
});

const wss$ = new BehaviorSubject<string | null>(null);
const inputWss$ = new BehaviorSubject<string | null>(null);
// const ws = new WS(wss$, inputWss$);

// ws.connnect();

export default function App() {
  const [apolloClient, setApolloClient] = React.useState<any>(null);
  React.useEffect(() => {
    const httpLink = new HttpLink({
      uri: process.env.REACT_APP_GRAPHQL_URL as string,
    });

    const apolloCache = new InMemoryCache();

    setApolloClient(
      new ApolloClient({
        cache: apolloCache,
        link: authLink.concat(logoutLink).concat(httpLink),
      })
    );
  }, []);

  if (!apolloClient) return null;

  return (
    <AppProvider>
      <LiveProvider wss$={wss$} inputWss$={inputWss$}>
        <ApolloProvider client={apolloClient}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{
              calendarWeekNumberHeaderText: "#",
              calendarWeekNumberText: (weekNumber) => `${weekNumber}.`,
            }}
          >
            <MaterialCssVarsProvider theme={{ [THEME_ID]: materialTheme }}>
              <CssVarsProvider defaultMode="dark" disableTransitionOnChange>
                <CssBaseline />
                <Outlet />
                <Notifications />
              </CssVarsProvider>
            </MaterialCssVarsProvider>
          </LocalizationProvider>
        </ApolloProvider>
      </LiveProvider>
    </AppProvider>
  );
}

import * as React from "react";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { Popper } from "@mui/base/Popper";
import Autocomplete from "@mui/joy/Autocomplete";
import AutocompleteListbox from "@mui/joy/AutocompleteListbox";
import AutocompleteOption from "@mui/joy/AutocompleteOption";
import ListSubheader from "@mui/joy/ListSubheader";
import { ListItemContent, Typography } from "@mui/joy";
import { Sku, useSkuListQuery } from "../__generated__/types-and-hooks";
import SearchIcon from "@mui/icons-material/Search";

const LISTBOX_PADDING = 6; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty("group")) {
    return (
      <ListSubheader key={dataSet.key} component="li" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <AutocompleteOption {...dataSet[0]} style={inlineStyle}>
      <ListItemContent
        sx={{
          fontSize: "sm",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {dataSet[1]?.sku}
        <Typography
          level="body-xs"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {dataSet[1].name}
        </Typography>
      </ListItemContent>
    </AutocompleteOption>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return (
    <AutocompleteListbox
      {...props}
      {...outerProps}
      component="div"
      ref={ref}
      sx={{
        "& ul": {
          padding: 0,
          margin: 0,
          flexShrink: 0,
        },
      }}
    />
  );
});

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  {
    anchorEl: any;
    open: boolean;
    modifiers: any[];
  } & React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, anchorEl, open, modifiers, ...other } = props;
  const itemData: Array<any> = [];
  (
    children as [
      Array<{ children: Array<React.ReactElement<any>> | undefined }>
    ]
  )[0].forEach((item) => {
    if (item) {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  });

  const itemCount = itemData.length;
  const itemSize = 40;

  return (
    <Popper
      style={{ zIndex: 1000 }}
      ref={ref}
      anchorEl={anchorEl}
      open={open}
      modifiers={modifiers}
    >
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          itemData={itemData}
          height={itemSize * 8}
          width="100%"
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </Popper>
  );
});

export interface SkuAutocompleteProps {
  teamId: number;
  sku: string | null;
  onChange: (sku: Sku | null) => void;
}

export function SkuAutocomplete({
  sku,
  teamId,
  onChange,
}: SkuAutocompleteProps) {
  const skuListQuery = useSkuListQuery({
    variables: { teamId },
  });

  const handleChanges = React.useCallback(
    (e: any, value: Sku | null) => {
      onChange(value);
    },
    [onChange]
  );

  const isLoading = skuListQuery.loading;
  const skus = skuListQuery.data?.sku_list?.skus;
  // if (!skus) return null;

  return (
    <Autocomplete
      size="sm"
      sx={{ width: "100%", fontSize: "14px" }}
      value={skus?.find((s) => s.sku === sku)}
      loading={isLoading}
      disableListWrap
      startDecorator={<SearchIcon />}
      placeholder="SKU"
      onChange={handleChanges as any}
      slots={{
        listbox: ListboxComponent,
      }}
      options={skus ?? []}
      groupBy={(option) => option.sku.split(/[^0-9a-zA-Z]/)[0].toUpperCase()}
      getOptionLabel={(option) => option.sku}
      renderOption={(props, option) => [props, option] as React.ReactNode}
      // TODO: Post React 18 update - validate this conversion, look like a hidden bug
      renderGroup={(params) => params as unknown as React.ReactNode}
      // filterOptions={filterOptions}
    />
  );
}

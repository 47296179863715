import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import ForgotPasswordForm, { ForgotPasswordVM } from "./ForgotPasswordForm";
import { useTranslation } from "../../i18n";

export default function ForgotPassword() {
  const t = useTranslation();

  return (
    <>
      <Stack gap={4} sx={{ mb: 2 }}>
        <Stack gap={1}>
          <Typography component="h1" level="h3">
            {t("Forgot Password")}
          </Typography>
          <Typography level="body-sm">
            {t("New to company?")}{" "}
            <Link href="/auth/sign-up" level="title-sm">
              {t("Sign up!")}
            </Link>
          </Typography>
        </Stack>
      </Stack>
      <Stack gap={4} sx={{ mt: 2 }}>
        <ForgotPasswordForm vm={new ForgotPasswordVM()} />
      </Stack>
    </>
  );
}

import AspectRatio from "@mui/joy/AspectRatio";
import {
  Button,
  CardActions,
  CardContent,
  CardOverflow,
  IconButton,
  Typography,
} from "@mui/joy";
import Card from "@mui/joy/Card";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import { useUserMeQuery } from "../../__generated__/types-and-hooks";
import { useTranslation } from "../../i18n";
import FileUploadButton from "../../components/FileUploadButton";
import { useNavigate } from "react-router-dom";

export default function MyProfileCard() {
  const t = useTranslation();
  const user = useUserMeQuery();
  const navigate = useNavigate();

  if (user.loading) return <div>Loading</div>;
  return (
    <Card
      sx={{
        textAlign: "center",
        alignItems: "center",
        width: "100%",
        // maxWidth: "220px",
        margin: "auto",
        // to make the demo resizable
        overflow: "auto",
        "--icon-size": "80px",
        bgcolor: "background.surface",
      }}
    >
      <CardOverflow variant="soft">
        <AspectRatio
          variant="outlined"
          ratio="1"
          sx={{
            m: "auto",
            transform: "translateY(50%)",
            borderRadius: "50%",
            width: "var(--icon-size)",
            boxShadow: "sm",
            bgcolor: "background.surface",
            position: "relative",
          }}
        >
          <img
            src={
              process.env.REACT_APP_API_URL +
              "/" +
              (user.data?.user_me.picture ?? "")
            }
            loading="lazy"
            alt=""
          />
        </AspectRatio>
        {user.data && (
          <IconButton
            component={"label"}
            variant="outlined"
            color="neutral"
            sx={{
              backdropFilter: "blur(10px)",
              border: 0,
              position: "absolute",
              left: 130,
              top: 85,
              borderRadius: "50%",
            }}
          >
            <>
              <FileUploadButton
                entityType="avatar"
                aria-label="Upload"
                size="sm"
                variant="outlined"
                color="neutral"
                component="label"
              />
              <FileUploadRoundedIcon />
            </>
          </IconButton>
        )}
      </CardOverflow>
      <CardContent sx={{ maxWidth: "40ch", mt: "calc(var(--icon-size) / 2)" }}>
        <div>
          <Typography level="title-lg">
            {user.data?.user_me.firstName} {user.data?.user_me.lastName}
          </Typography>
          <Typography level="body-sm">{user.data?.user_me.email}</Typography>
          <Typography level="body-sm">
            {user.data?.user_me.country.label}
          </Typography>
          {user.data?.user_me.role !== "USER" && (
            <Typography level="body-sm">{user.data?.user_me.role}</Typography>
          )}
          <Typography level="body-sm">{user.data?.user_me.bio}</Typography>
        </div>
      </CardContent>
      <CardActions
        orientation="horizontal"
        buttonFlex={1}
        sx={{
          width: "clamp(min(100%, 200px), 50%, min(100%, 200px))",
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={() => navigate("/me")}
        >
          {t("Edit")}
        </Button>
      </CardActions>
    </Card>
  );
}

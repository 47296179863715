import Button from "@mui/joy/Button";
import { Chip, FormControl, FormLabel, Input, Textarea } from "@mui/joy";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import { Typography } from "@mui/joy";
import { Stack } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import React, { useCallback } from "react";
import { useTranslation } from "../../../i18n";
import { useTeamCreateMutation } from "../../../__generated__/types-and-hooks";
import { QCSKULogo } from "../../../components/QCSKULogo";

export interface CreateTeamProps {
  kind: "agency" | "brand";
}

export default function CreateTeam({ kind }: CreateTeamProps) {
  const navigate = useNavigate();
  const t = useTranslation();
  const slugRef = React.useRef<HTMLInputElement>(null);
  const nameRef = React.useRef<HTMLInputElement>(null);

  const [teamCreateMutation, { loading }] = useTeamCreateMutation();

  const handleCreate = useCallback(async () => {
    const team = await teamCreateMutation({
      variables: {
        slug: slugRef.current?.value ?? "",
        name: nameRef.current?.value ?? "",
        kind: kind.toUpperCase(),
      },
    });
    navigate("/next-steps", {
      state: {
        text: `You have successfully created ${team?.data?.team_create.name}.`,
        primaryAction: {
          text: `Open ${team?.data?.team_create.name}`,
          link: `/${team?.data?.team_create.slug}`,
        },
      },
    });
  }, [teamCreateMutation, slugRef, nameRef, kind, navigate]);

  return (
    <Stack gap={4} sx={{ mb: 2 }}>
      <Stack gap={1}>
        <Card
          variant="outlined"
          sx={{
            boxShadow: "sm",
            textAlign: "center",
            alignItems: "center",
            width: 343,
            margin: "auto",
            // to make the demo resizable
            overflow: "auto",
            "--icon-size": "100px",
            bgcolor: "background.surface",
          }}
        >
          <CardOverflow variant="soft">
            <QCSKULogo />
          </CardOverflow>
          <Typography
            level="title-lg"
            sx={{ mt: "calc(var(--icon-size) / 2)" }}
          >
            {kind === "brand" && t("Create Brand")}
            {kind === "agency" && t("Create Agency")}
          </Typography>
          <CardContent>
            <FormControl>
              <FormLabel>
                <FormLabel>
                  <Stack direction="column">
                    <Typography textAlign="left">{t("Slug")}</Typography>
                    <Typography
                      textAlign="left"
                      color="neutral"
                      level="body-xs"
                    >
                      {t("A unique identifier for your team's dashboard.")}
                    </Typography>
                  </Stack>
                </FormLabel>
              </FormLabel>
              <Input
                slotProps={{ input: { sx: { width: 0 }, ref: slugRef } }}
                size="sm"
                placeholder="slug"
                defaultValue=""
                startDecorator={
                  <Chip sx={{ borderRadius: 4 }} color="primary">
                    https://app.qcsku.com/
                  </Chip>
                }
              />
            </FormControl>

            <FormControl>
              <FormLabel>
                {t("Name")}
                <Typography
                  color="neutral"
                  level="body-xs"
                  sx={{ opacity: "70%" }}
                >
                  {t("The name that will be visible to others on QCSKU.")}
                </Typography>
              </FormLabel>
              <Input
                slotProps={{ input: { ref: nameRef } }}
                size="sm"
                placeholder="Name"
                defaultValue=""
              />
            </FormControl>

            <FormControl>
              <FormLabel>
                {t("Description")}
                <Typography
                  color="neutral"
                  level="body-xs"
                  sx={{ opacity: "70%" }}
                >
                  {t("Tell others about your team and what you offer.")}
                </Typography>
              </FormLabel>
              <Textarea size="sm" placeholder="Description" minRows={4} />
            </FormControl>
          </CardContent>
          <CardActions
            orientation="vertical"
            buttonFlex={1}
            sx={{
              "--Button-radius": "40px",
              width: "clamp(min(100%, 200px), 50%, min(100%, 200px))",
            }}
          >
            <Button
              loading={loading}
              variant={"solid"}
              color={"primary"}
              onClick={handleCreate}
            >
              {t("Create")}
            </Button>
            <Button
              variant="plain"
              color="primary"
              onClick={() => navigate("/")}
            >
              {t("Go Back")}
            </Button>
          </CardActions>
        </Card>
      </Stack>
    </Stack>
  );
}

import React, { useEffect, useState } from "react";
import { BehaviorSubject, Observable } from "rxjs";

export function useRx<T>(subject$: BehaviorSubject<T>) {
  const [state, setState] = React.useState<T>(subject$.value);
  React.useEffect(() => {
    const subscription = subject$.subscribe({
      next(data) {
        setState(data);
      },
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [subject$]);
  return state;
}

export function useRxVal<T>(observable$?: Observable<T>) {
  const [data, setData] = useState<T>(
    (observable$ as unknown as BehaviorSubject<T>)?.value
  );
  useEffect(() => {
    const subscription = observable$?.subscribe({ next: setData });
    return () => subscription?.unsubscribe();
  }, [observable$]);
  return data;
}

import FactoryIcon from "@mui/icons-material/Factory";

export function FAIcon() {
  return <FactoryIcon />;
}

export const FALabel = "Factory Audit";
export const FALabelShort = "Factory";

export const FAValue = "FA";

export const FADescription =
  "Assessment of a factory's operations to ensure that it meets regulatory guidelines, industry standards, and customer requirements";

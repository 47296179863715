import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputTypeMap,
} from "@mui/joy";
import { ReactNode, useCallback } from "react";
import { Observable, Subject } from "rxjs";
import { useRxVal } from "../hooks/useRx";

export interface TextInput$Props {
  label?: string | ReactNode;
  val$: Subject<string>;
  error$?: Observable<string | null>;
  showError?: boolean;
  required?: boolean;
}

export function XTextInput({
  val$,
  error$,
  showError,
  label,
  required,
  ...props
}: InputTypeMap<{}, "div">["props"] & TextInput$Props) {
  const val = useRxVal(val$);
  const error = useRxVal(error$);

  const handleChanges = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      val$.next(e.target.value);
    },
    [val$]
  );

  return (
    <FormControl required={required} error={showError && !!error}>
      {label && <FormLabel>{label}</FormLabel>}
      <Input
        {...props}
        onChange={handleChanges}
        onBlur={handleChanges}
        value={val}
      />
      {showError && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}

import { filter, map, Observable, Subject } from "rxjs";
import { computed } from "../decorators/computed";
import { PlatformEvent } from "./platform-event";

export type LiveInteraction = {
  action: string;
  entityId: string;
  entityType: string;
};

export class Live {
  constructor(
    private wss$: Observable<string | null>,
    private inputWss$: Subject<string | null>
  ) {}

  @computed
  get platformEvent$() {
    return this.wss$.pipe(
      filter((msg) => typeof msg === "string"),
      map((str) => JSON.parse(str as string) as PlatformEvent)
    );
  }

  protected parsePlatformEventData(platformEvent: PlatformEvent, key: string) {
    const data = platformEvent.data.find((item) => item.key === key);
    if (data?.value) return JSON.parse(data.value);
    return null;
  }

  @computed
  get online$(): Observable<number[]> {
    return this.platformEvent$.pipe(
      filter(
        (platformEvent) =>
          platformEvent.entityType === "user" &&
          (platformEvent.action === "online" ||
            platformEvent.action === "offline")
      ),
      map((platformEvent) =>
        this.parsePlatformEventData(platformEvent, "online-users")
      )
    );
  }

  @computed
  get gridInteraction$() {
    return this.platformEvent$.pipe(
      filter((platformEvent) => platformEvent.entityType === "grid"),
      map((platformEvent) => ({
        ...platformEvent,
        ...this.parsePlatformEventData(platformEvent, "grid-actions"),
      }))
    );
  }

  publish(liveInteraction: LiveInteraction) {
    this.inputWss$.next(JSON.stringify(liveInteraction));
  }
}

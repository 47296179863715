import * as React from "react";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Typography from "@mui/joy/Typography";
import { Subject } from "rxjs";
import { useRxVal } from "../hooks/useRx";
import { Box, Chip } from "@mui/joy";
import CheckBoxIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";

type UserTeamRole = {
  id: string;
  label: string;
};

export interface TeamRolesSelectProps {
  roles$: Subject<UserTeamRole[]>;
}

const allRoles = [
  {
    id: "OWNER",
    label: "Owner",
  },
  {
    id: "ADMIN",
    label: "Admin",
  },
  {
    id: "MANAGER",
    label: "Manager",
  },
  {
    id: "MEMBER",
    label: "Member",
  },
  {
    id: "OBSERVER",
    label: "Observer",
  },
];

export function TeamRolesSelect({ roles$ }: TeamRolesSelectProps) {
  const selectedRoles = useRxVal(roles$);
  const selectedRolesIds = new Set(selectedRoles.map((role) => role.id));

  const handleSelectRoles = React.useCallback(
    (_event: any, value: UserTeamRole[]) => {
      console.log("roles", value);
      roles$.next(value);
    },
    [roles$]
  );

  return (
    <Select
      multiple
      onChange={handleSelectRoles}
      value={selectedRoles}
      renderValue={(selected) => (
        <Box sx={{ display: "flex", gap: "0.25rem" }}>
          {selected.map((role) => (
            <Chip variant="soft" color="primary">
              {role.label}
            </Chip>
          ))}
        </Box>
      )}
      sx={{ minWidth: 240 }}
    >
      {allRoles.map((data, index) => (
        <Option key={data.id} value={data}>
          {selectedRolesIds.has(data.id) ? (
            <CheckBoxIcon fontSize="small" />
          ) : (
            <CheckBoxOutlineBlankIcon fontSize="small" />
          )}
          <Typography component="span" level="title-sm">
            {data.label}
          </Typography>
        </Option>
      ))}
    </Select>
  );
}
